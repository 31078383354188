import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { catchError, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (req.url.includes('auth') && !req.url.includes('password/change') && !req.url.includes('current-user')) ||
      req.url.includes('assets') || req.url.includes('api.openai') || req.url.includes('password/change/confirm')
      || req.url.includes('mapbox')
    ) {
      return next.handle(req);
    }

    let preObs$;

    //if more than 1 hr passed since the last token refresh - refresh it again
    if (Date.now() - this.authService.lastTokenUpdate.getTime() > 3600000) {
      preObs$ = this.authService.refreshToken();
    } else {
      preObs$ = of(true);
    }

    // @ts-ignore
    return preObs$.pipe(
      // @ts-ignore
      switchMap(() => {
        const value = localStorage.getItem('accessToken');

        if (value) {
          req = req.clone({ setHeaders: { Authorization: `Bearer ${value}` } });

          return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.router.navigate(['/auth/login']);
              }

              return throwError(() => err);
            })
          );
        } else {
          this.router.navigate(['/auth/login']);
          console.warn('Not logged in');
        }
      })
    );
  }
}
