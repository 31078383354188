import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {map, Observable, tap} from 'rxjs';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {FORBIDDEN_PATH} from '../app-routing.module';
import {MAX_QUESTS_PER_DAY} from '../global.constants';

@Injectable({
  providedIn: 'root'
})
export class QuestLimitPerDayGuardService  {

  constructor(private questService: QuestsCrudService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!route.url.some(v => v.path === 'new')) {
      return true;
    }

    return this.questService.countQuestsCreatedInLastDay$().pipe(
      map(count => count < MAX_QUESTS_PER_DAY),
      tap(canCreate => {
        if (!canCreate) {
          this.router.navigate([`${FORBIDDEN_PATH}/too-many-quests`])
        }
      }),
    );
  }
}
