import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MY_QUESTS_PATH} from '../../app-routing.module';

@Component({
  selector: 'app-forbidden-too-many-quests',
  templateUrl: './forbidden-too-many-quests.component.html',
  styleUrls: ['./forbidden-too-many-quests.component.scss']
})
export class ForbiddenTooManyQuestsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navToHome(): void {
    this.router.navigate([MY_QUESTS_PATH]);
  }

}
