import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestsSharingService {
  readonly ROOT_URL = `${environment.API_URL}/quests`;

  constructor(private http: HttpClient) { }

  createToken(questId: number, role: 'collaborator' | 'player'): Observable<{token: string}> {
    return this.http.post<{token: string}>(`${this.ROOT_URL}/tokens/`, {quest_id: questId, role});
  }
}
