import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs';

@Component({
  selector: 'app-proxy-to-map-redirect',
  standalone: true,
  imports: [],
  templateUrl: './proxy-to-map-redirect.component.html',
  styleUrl: './proxy-to-map-redirect.component.scss'
})
export class ProxyToMapRedirectComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      if(params['id']) {
        this.router.navigate(['main-map'], {queryParams: {quest: params['id']}});
      }
    })
  }

}
