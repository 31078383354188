<mat-toolbar class="toolbar top">
  <span class="main-heading-text">{{ title }}</span>
  <img class="pointer" src="assets/project-icons/x.svg" alt="X" (click)="close.emit()" />
</mat-toolbar>

<div class="map-editor-container">
  <div class="controls" *transloco="let t">
    <h4>{{t('stepObjectives')}}</h4>

    @if (questObjectiveType === QuestStepObjectiveType.markerReach) {
      <div class="controls-row">
        <button
          class="secondary-bar-btn add-buttons"
          (click)="clickMarkerReachBtn()"
          [ngClass]="{ active: editMode === 'reachMarker' }"
          [disabled]="!!markerReachEditEngine.getData().coords"
          >
          <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
            <use xlink:href="assets/project-icons/add.svg#a"></use>
          </svg>
          {{t('addPlace')}} ({{t('required')}})
        </button>
        <app-tooltip [paragraphs]="[t('addPlaceTooltipP1'), t('addPlaceTooltipP2')]"></app-tooltip>
      </div>
      <div class="chips-container">
        @if (!!markerReachEditEngine.getData().coords) {
          <app-active-chip
            [text]="markerReachEditEngine.getData().name"
            [selected]="markerReachEditEngine.editing"
            [color]="!!markerReachEditEngine.getData().coords || startZoneEditEngine.isValid() ? 'default' : 'red'"
            (click)="clickMarkerReachBtn()"
            (close)="markerReachEditEngine.clear(); stopCurrentEdit()"
            >
          </app-active-chip>
        }
      </div>
    }
    @if (questObjectiveType === QuestStepObjectiveType.markerFind) {
      <div class="controls-row">
        <button
          class="secondary-bar-btn add-buttons"
          (click)="clickMarkerFindBtn()"
          [ngClass]="{ active: editMode === 'findMarker' }"
          [disabled]="!!markerFindEditEngine.getData().coords"
          >
          <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
            <use xlink:href="assets/project-icons/add.svg#a"></use>
          </svg>
          {{t('addHiddenPlace')}} ({{t('required')}})
        </button>
        <app-tooltip [paragraphs]="[t('addHiddenPlaceTooltipP1'), t('addHiddenPlaceTooltipP2'), t('addHiddenPlaceTooltipP3')]"></app-tooltip>
      </div>
      @if (!!markerFindEditEngine.getData().coords) {
        <div class="controls-row margin-top-18">
          <!--        TODO to show dragged part of the range as blue, we need to create a custom wrapper component over input where that blue part will actually be another div-->
          <input type="range" min="50" max="1000" [formControl]="markerFindEditEngine.radiusControl" class="general-range" />
          <span class="range-label">
            {{ metersToPretty(markerFindEditEngine.radiusControl.value) }}
          </span>
        </div>
      }
      <div class="chips-container">
        @if (!!markerFindEditEngine.getData().coords) {
          <app-active-chip
            [text]="markerFindEditEngine.getData().name"
            [selected]="markerFindEditEngine.editing"
            [color]="!!markerFindEditEngine.getData().coords || markerFindEditEngine.isValid() ? 'default' : 'red'"
            (click)="clickMarkerFindBtn()"
            (close)="markerFindEditEngine.clear(); stopCurrentEdit()"
            >
          </app-active-chip>
        }
      </div>
    }

    @if (!config?.startZoneHidden) {
      <h4>{{t('startArea')}}</h4>
      <div class="controls-row">
        <button
          class="secondary-bar-btn add-buttons"
          (click)="clickStartZoneBtn()"
          [ngClass]="{ active: editMode === 'startZone' }"
          [disabled]="!startZoneEditEngine.getData().empty"
          >
          <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
            <use xlink:href="assets/project-icons/add.svg#a"></use>
          </svg>
          {{t('startArea')}} ({{t('optional')}})
        </button>
        <app-tooltip [paragraphs]="[t('startAreaTooltipP1'), t('startAreaTooltipP2')]"></app-tooltip>
      </div>
      @if (!startZoneEditEngine.getData().empty) {
        <div class="controls-row margin-top-18">
          <input type="range" min="4" max="300" [formControl]="startZoneEditEngine.radiusControl" class="general-range" />
          <span class="range-label">
            {{ metersToPretty(startZoneEditEngine.radiusControl.value) }}
          </span>
        </div>
      }
      <div class="chips-container">
        @if (!startZoneEditEngine.getData().empty) {
          <app-active-chip
            [text]="startZoneEditEngine.getData().name"
            [selected]="startZoneEditEngine.editing"
            [color]="startZoneEditEngine.getData().empty || startZoneEditEngine.isValid() ? 'default' : 'red'"
            (click)="clickStartZoneBtn()"
            (close)="startZoneEditEngine.clear(); stopCurrentEdit()"
            >
          </app-active-chip>
        }
      </div>
    }

    <h4>{{t('stepHazardZones')}}</h4>

    <div class="controls-row">
      <button class="secondary-bar-btn add-buttons"
        [ngClass]="{ active: editMode === 'hazardZone' }"
        (click)="clickHazardZoneBtn(addNewHazardZone())">
        <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
          <use xlink:href="assets/project-icons/add.svg#a"></use>
        </svg>
        {{t('hazardZone')}} ({{t('optional')}})
      </button>

      <app-tooltip
        [paragraphs]="[t('hazardZoneTooltipP1')]"
      ></app-tooltip>
    </div>

    <div class="chips-container">
      @for (zone of hazardZonesCluster.instances; track zone; let i = $index) {
        <app-active-chip
          [text]="zone.zoneName"
          [selected]="zone.editing"
          [color]="zone.getData().empty || zone.isValid() ? 'default' : 'red'"
          (click)="clickHazardZoneBtn(zone)"
          (close)="removeHazardZone(i)"
          >
        </app-active-chip>
      }
    </div>

    <h4>{{t('stepDuration')}}</h4>

    <div class="controls-row">
      <button class="secondary-bar-btn add-buttons" (click)="clickStepDurationButton()">
        <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
          <use xlink:href="assets/project-icons/add.svg#a"></use>
        </svg>

        {{t('stepDuration')}} ({{t('optional')}})
      </button>

      <app-tooltip [paragraphs]="[t('stepDurationTooltipP1'), t('stepDurationTooltipP2')]"></app-tooltip>
    </div>

    @if (isTimerVisible || config?.timer) {
      <div [formGroup]="form">
        <mat-form-field appearance="fill" class="form-field margin-top-24 step-duration-input" color="accent">
          <mat-label>{{t('stepDuration')}}</mat-label>
          <input class="step-timer" matInput formControlName="timer" type="text" mask="Hh:m0:s0" placeholder="00:00:00"/>
        </mat-form-field>
      </div>
    }
  </div>

  <div id="map-container" class="map">
    @if ((mapControlVisibility$ | async)?.state === 'show') {
      <div class="map-controls">
        @if ((mapControlVisibility$ | async)?.showConfirm) {
          <div class="map-control-slot pointer" (click)="mapControlPanelActions$.next({ button: 'confirm' })">
            <svg class="regular-img-size color-green">
              <use xlink:href="assets/project-icons/check.svg#c"></use>
            </svg>
          </div>
        }
      </div>
    }

    <button class="map-btn" style="top: 156px" (click)="goToMyLocation()">
      <svg class="pointer" viewBox="0 0 24 24" width="20" height="20">
        <use xlink:href="assets/map-icons/location.svg#c"></use>
      </svg>
    </button>

    <button class="map-btn" style="top: 200px" (click)="toggleMagStyle()">
      <svg class="pointer" viewBox="0 0 24 24" width="20" height="20">
        <use xlink:href="assets/map-icons/layers.svg#c"></use>
      </svg>
    </button>
  </div>
</div>

<mat-toolbar class="toolbar bottom element-shadow">
  <span class="filler"></span>
  <button class="primary-bar-btn" (click)="applyClick.emit(apply())" [disabled]="!isValid()">
    <svg class="regular-img-size">
      <use xlink:href="assets/project-icons/check.svg#c"></use>
    </svg>
    {{'apply' | transloco}}
  </button>
</mat-toolbar>
