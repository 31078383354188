import {ChangeDetectorRef, Component, effect} from '@angular/core';
import {SvgIconsService} from "./services/svg-icons.service";
import {GlobalSpinnerService} from './services/global-spinner.service';
import {TranslocoService} from '@ngneat/transloco';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private iconsService: SvgIconsService,
              public globalSpinnerService: GlobalSpinnerService,
              private cdRef: ChangeDetectorRef,
              transloco: TranslocoService) {
    moment.locale(transloco.getActiveLang());

    iconsService.registerIcons();
    effect(() => {
      // this is a hacky way to be subscribed to the spinner changes
      if (this.globalSpinnerService.isShowSpinner()) {
        this.cdRef.detectChanges();
      } else {
        this.cdRef.detectChanges();
      }
    });
  }
}
