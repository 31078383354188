import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QuestsChainsService} from '../quests-chains/quests-chains.service';
import {Subject, takeUntil} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {QuestsChain} from '../quests-chains/interfaces/quests-chains.core.interfaces';
import {Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-quests-chains-list',
  templateUrl: './quests-chains-list.component.html',
  styleUrls: ['./quests-chains-list.component.scss']
})
export class QuestsChainsListComponent implements OnInit, AfterViewInit, OnDestroy {

  dataSource: MatTableDataSource<QuestsChain>;
  displayedColumns: string[] = ['name', 'updatedAt', 'createdAt'];

  @ViewChild(MatSort) sort: MatSort;

  private destroy$ = new Subject<void>();

  constructor(private questsChainsService: QuestsChainsService,
              private router: Router) { }

  ngOnInit(): void {
    this.questsChainsService.loadChains$().pipe(takeUntil(this.destroy$)).subscribe((chains) => {
      chains = chains.sort((a, b) => {
        return a.updatedAgo < b.updatedAgo ? -1 : 1;
      });

      this.dataSource = new MatTableDataSource<QuestsChain>(chains);

      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
    });
  }


  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  selectChain(chain: QuestsChain): void {
    this.router.navigate(['quests-chains', chain.id]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
