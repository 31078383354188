import {Component, OnInit, signal} from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { QuestMetadata } from 'quest-atlas-angular-components';
import { QuestsCrudService } from 'quest-atlas-angular-components';
import {map, take, tap} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalButton } from '../../common-components/modal/modal.component';
import { QuestsSharingService } from '../../services/quests-sharing.service';
import { SHARED_QUESTS_PATH } from '../../app-routing.module';
import {TranslocoService} from '@ngneat/transloco';
import {QuestFilters} from 'quest-atlas-angular-components';

@Component({
  selector: 'app-quest-list',
  templateUrl: './quest-list.component.html',
  styleUrls: ['./quest-list.component.scss']
})
export class QuestListComponent implements OnInit  {
  quests$ = this.questService.quests$;
  loading = signal(false);
  showDeleteConfirmation: boolean;
  questIdToRemove: string = '';
  modalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => (this.showDeleteConfirmation = false)
    },
    {
      type: 'error',
      text: this.transloco.translate('delete'),
      onClick: () => this.deleteQuest(this.questIdToRemove)
    }
  ];
  type: 'my' | 'shared' = 'my';

  constructor(
    private questService: QuestsCrudService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _liveAnnouncer: LiveAnnouncer,
    private transloco: TranslocoService,
    private questsSharingService: QuestsSharingService
  ) {}

  ngOnInit() {
    this.loading.set(true);
    this.activatedRoute.url
      .pipe(
        map((urlSegment) => urlSegment[0].path.includes(SHARED_QUESTS_PATH) ? 'shared' : 'my'),
        tap((type) => this.type = type),
        switchMap((type) => {
          const filters: QuestFilters = {
            access: {
              private: type !== 'shared',
              shared: type === 'shared',
              onlyCollaborative: type === 'shared'
            }
          }

          return this.questService.loadQuests(null, null, false, filters).pipe(take(1));
        }),
        tap(() => {
          this.loading.set(false);
        })
      )
      .subscribe((quests) => this.questService.updateQuestsSubject(quests));
  }

  navigateToQuest(quest: QuestMetadata) {
    this.router.navigate([`./single/${quest.id}`], { relativeTo: this.activatedRoute });
  }

  navigateToEditQuest(quest: QuestMetadata) {
    this.router.navigate([`/quest-editor/${quest.id}`] );
  }

  handleQuestDelete(questId: string | number) {
    this.showDeleteConfirmation = true;
    this.questIdToRemove = questId as string;
  }

  deleteQuest(questId: string) {
    this.questService
      .deleteQuest(questId)
      .pipe(switchMap(() => this.quests$.pipe(take(1))))
      .subscribe({
        next: (quests) => {
          this.questService.updateQuestsSubject(quests.filter((questItem) => questItem.id !== questId));
          this.questIdToRemove = '';
          this.close();
        },
        error: (err) => {
          console.error('err', err, JSON.stringify(err));
        }
      });
  }

  close(): void {
    this.showDeleteConfirmation = false;
  }

  copyShareTokenToClipboard(questId: number, role: 'collaborator' | 'player'): void {
    this.questsSharingService.createToken(questId, role).subscribe((value) => {
      const link = `${window.location.origin}/accept-share/${value.token}`;
      navigator.clipboard.writeText(link);
      alert(`${this.transloco.translate('link')} ${link} ${this.transloco.translate('linkCopied')}`);
    });
  }
}
