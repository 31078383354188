import { Injectable } from '@angular/core';
import {User} from 'quest-atlas-angular-components';
import {environment} from '../../environments/environment';
import {BehaviorSubject, map, mergeWith, Observable, share, Subject, tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly BASE_URL = `${environment.API_URL}/`;
  private latestUserData: User;
  private manualUserDataUpdate$ = new Subject<User>();
  private triggerReloadUserInfo$ = new BehaviorSubject<void>(void 0);

  constructor(private http: HttpClient, private ds: DomSanitizer) {
  }

  getUserInfo$(): Observable<User> {
    return this.triggerReloadUserInfo$.pipe(
      switchMap(() => this.http.get<any>(`${this.BASE_URL}auth/current-user/`)),
      map(value => ({
        id: value.id,
        username: value.username,
        email: value.email,
        isFirstLogIn: value.is_first_time_login,
        isSuperUser: value.is_superuser,
        organizations: value.organizations ? value.organizations.map(org => ({
          id: org.organization__id,
          type: org.organization__type,
          name: org.organization__name,
          logo: this.ds.bypassSecurityTrustHtml(org.organization__logo),
          secondaryLogo: org.organization__secondary_logo,
          role: org.role,
        })) : [],
        leveling: {
          level: value.level,
          xp: value.experience_on_cur_level,
          xpToNextLevel: value.experience_to_new_level,
        }
      })),
      tap(value => console.log('loaded user info', value)),
      mergeWith(this.manualUserDataUpdate$),
      tap(value => this.latestUserData = value),
      share(),
    );
  }

  deleteUser$(fullWipe = false): Observable<void> {
    const httpParams = fullWipe ? {params: {'full-wipe': 'true'}} : {};
    return this.http.delete<void>(`${this.BASE_URL}auth/current-user/delete`, {...httpParams});
  }

  triggerUserInfoReload() {
    this.triggerReloadUserInfo$.next(void 0);
  }

  updateUsername$(username: string): Observable<void> {
    return this.http.patch<void>(`${this.BASE_URL}auth/current-user/update`, {new_username: username})
      .pipe(tap(() => {
        this.latestUserData.username = username;
        this.manualUserDataUpdate$.next(this.latestUserData);
      }));
  }
}
