<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="sortData($event)" *transloco="let t">
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header="name" sortActionDescription="Sort by name" *matHeaderCellDef>{{t('name')}}</th>
    <td mat-cell *matCellDef="let quest">
      <div class="name-col-container">
        <div class="quest-image-container">
          @if (quest?.info?.images.length) {
            <img [src]="quest.info?.images[0]?.url" alt="" />
          }
        </div>
        <div>{{ quest.name }}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef>{{t('address')}}</th>
    <td mat-cell *matCellDef="let quest">{{ quest.info.address }}</td>
  </ng-container>

  <ng-container matColumnDef="updatedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt" sortActionDescription="Sort by last modified">{{t('lastModified')}}</th>
    <td mat-cell *matCellDef="let quest">{{ quest.updatedAgo }}</td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt" sortActionDescription="Sort by created">{{t('created')}}</th>
    <td mat-cell *matCellDef="let quest">{{ quest.createdAgo }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="type" sortActionDescription="Sort by type">{{t('type')}}</th>
    <td mat-cell *matCellDef="let quest">{{ quest.adaptive ? t('adaptive') : t('regular') }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" sortActionDescription="Sort by status">{{t('state')}}</th>
    <td mat-cell *matCellDef="let quest"
      [ngClass]="{'color-green': getQuestStatus(quest) === 'Public', 'color-soft-grey': getQuestStatus(quest) === 'Draft'}">
      {{ getQuestStatus(quest) }}
    </td>
  </ng-container>

  @if (showMenu) {
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let quest" class="menu-table-data">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit.emit(quest)">
            <span>{{t('toEdit')}}</span>
          </button>
          <button mat-menu-item (click)="shareCollaborator.emit(quest)">
            <span>{{t('shareToCollaborate')}}</span>
          </button>
          <button mat-menu-item (click)="sharePlayer.emit(quest)">
            <span>{{t('shareToPlay')}}</span>
          </button>
          <!--        TODO this is to unshare. Implement later-->
          <!--        <button mat-menu-item disabled>-->
          <!--          <span>{{t('toDecline')}}</span>-->
        <!--        </button>-->
        <button mat-menu-item (click)="delete.emit(quest)">
          <span>{{t('delete')}}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
}

<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select.emit(row)"></tr>
</table>
