import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {QuestsChain} from './interfaces/quests-chains.core.interfaces';
import {Observable, forkJoin, map} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Dict} from 'quest-atlas-angular-components';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {QuestMetadata} from 'quest-atlas-angular-components';
import {switchMap} from 'rxjs/operators';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class QuestsChainsService {

  private readonly ROOT_URL = '/quests/chain'

  constructor(private http: HttpClient,
              private questsService: QuestsCrudService) { }

  createChain(chain: QuestsChain): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}${this.ROOT_URL}`, this.transformForServer(chain));
  }

  patchChain(chain: QuestsChain, deleteSubPhasesIds: number[] = []): Observable<void> {
    return this.http.patch<void>(`${environment.API_URL}${this.ROOT_URL}/${chain.id}`, {
      ...this.transformForServer(chain),
      delete_sub_phases: deleteSubPhasesIds
    });
  }

  loadChains$(): Observable<QuestsChain[]> {
    return this.http.get<Dict<any>[]>(`${environment.API_URL}${this.ROOT_URL}`).pipe(
      map((chains) => chains.map((chain) => this.transformFromServerLite(chain)))
    );
  }

  loadChainById(id: number): Observable<QuestsChain> {
    return this.http.get<Dict<any>>(`${environment.API_URL}${this.ROOT_URL}/${id}`).pipe(
      switchMap((chain) => this.transformFromServer(chain))
    );
  }

  deleteChain(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}${this.ROOT_URL}/${id}`);
  }

  private transformForServer(chain: QuestsChain): Dict<any> {
    return {
      id: chain.id,
      name: chain.name,
      description: chain.description,
      sub_phases: chain.subPhases.map((subPhase, index) => {
        return {
          id: subPhase.id,
          name: subPhase.name,
          description: subPhase.description,
          quests_ids: subPhase.quests.map((quest) => quest.id),
          previous_sub_phases_names: index < 1 ? [] : [chain.subPhases[index - 1].name]
        }
      })
    }
  }

  private transformFromServer(chain: Dict<any>): Observable<QuestsChain> {
    // we flatten the quests ids arrays and then load all quests by ids
    return forkJoin(chain['sub_phases'].reduce((acc, subPhase) => {
      return [...acc, ...subPhase['quests']]
    }, []).map((questId) => this.questsService.loadQuestById(questId)) as Observable<QuestMetadata>[]).pipe(
      map((quests) => {
        const questsMap = new Map<number, QuestMetadata>();
        quests.forEach((quest) => {
          questsMap.set(quest.id, quest);
        });

        return questsMap
      }),
      map((questsMap) => {
        return {
          id: chain['id'],
          name: chain['name'],
          description: chain['description'],
          createdAt: new Date(chain['created_at']),
          updatedAt: new Date(chain['updated_at']),
          createdAgo: moment(chain['created_at']).fromNow(),
          updatedAgo: moment(chain['updated_at']).fromNow(),
          subPhases: chain['sub_phases'].map((subPhase) => {
            return {
              id: subPhase['id'],
              name: subPhase['name'],
              description: subPhase['description'],
              quests: subPhase['quests'].map((questId) => questsMap.get(questId))
            }
          })
        }
      })
    );
  }

  private transformFromServerLite(chain: Dict<any>): QuestsChain {
    return {
      id: chain['id'],
      name: chain['name'],
      description: chain['description'],
      createdAt: new Date(chain['created_at']),
      updatedAt: new Date(chain['updated_at']),
      createdAgo: moment(chain['created_at']).fromNow(),
      updatedAgo: moment(chain['updated_at']).fromNow(),
      subPhases: chain['sub_phases'].map((subPhase) => {
        return {
          id: subPhase['id'],
          name: subPhase['name'],
          description: subPhase['description'],
          questsIds: subPhase['quests']
        }
      })
    }
  }
}
