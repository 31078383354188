<div class="container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header="name" sortActionDescription="Sort by name" *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let chain">
        <div class="name-col-container">
          <div>{{ chain.name }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt" sortActionDescription="Sort by last modified">Last modified</th>
      <td mat-cell *matCellDef="let chain">{{ chain.updatedAgo }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt" sortActionDescription="Sort by created">Created</th>
      <td mat-cell *matCellDef="let chain">{{ chain.createdAgo }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectChain(row)"></tr>
  </table>
</div>
