<mat-toolbar id="header" class="toolbar top element-shadow">
  <span class="main-heading-text">{{ title }}</span>
  <img class="pointer" src="assets/project-icons/x.svg" alt="X" (click)="askToClose()"/>
</mat-toolbar>

<div id="quest-constructor-container">
  <div class="scrollable-content centered">
    <app-stepper class="constructor-stepper" [steps]="steps"></app-stepper>
    @if (!showMapEditor && (isNew ? true : !!questId)) {
      @switch ((currentStep$ | async)?.name) {
        @case ('General') {
          <app-general-form
            #generalForm
            [adaptive]="adaptive"
            [formValues]="generalFormValues"
            [cacheFormData]="isNew"
          ></app-general-form>
        }
        @case ('Steps') {
          <app-steps-form
            #stepsForm
            [adaptive]="adaptive"
            [formValues]="stepsFormValues"
            [cacheFormData]="isNew"
            [userGeo]="userGeo"
            (requestMapOpen)="openMapEditorForStep($event)"
          ></app-steps-form>
        }
        @case ('Options') {
          <app-options-form
            #optionsForm
            [adaptive]="adaptive"
            [formValues]="optionsFormValues"
            [cacheFormData]="isNew"
          ></app-options-form>
        }
        @case ('Publishing') {
          @if (questToSave) {
            <app-publish-view #publishingView [quest]="questToSave"></app-publish-view>
          }
        }
      }
    }
  </div>
</div>

<mat-toolbar id="footer" class="toolbar content-centered bottom element-shadow" *transloco="let t">
  @if ((currentStep$ | async) !== steps[0]) {
    <button class="secondary-bar-btn footer-button"
      (click)="clickBack()"
      >
      <svg viewBox="0 0 24 24" width="16" height="16" style="transform: rotate(180deg)">
        <use xlink:href="assets/project-icons/arrow.svg#a"></use>
      </svg>
      {{t('back')}}
    </button>
  } @else {
    <span></span>
  }
  @if ((currentStep$ | async) !== steps[steps.length - 1]) {
    <button class="primary-bar-btn footer-button"
      (click)="clickNext()"
      [disabled]="!currentStep || !currentStep.isFormValid()">
      {{t('next')}}
      <svg viewBox="0 0 24 24" width="16" height="16">
        <use xlink:href="assets/project-icons/arrow.svg#a"></use>
      </svg>
    </button>
  } @else {
    <button class="primary-bar-btn footer-button" (click)="saveQuest(true)">
      <svg viewBox="0 0 24 24" width="16" height="16" style="margin-right: 4px;">
        <use xlink:href="assets/project-icons/pencil.svg#c"></use>
      </svg>
      {{t('saveDraft')}}
    </button>
    <button class="primary-bar-btn footer-button" (click)="saveQuest()">
      <svg viewBox="0 0 24 24" width="16" height="16" style="margin-right: 4px;">
        <use xlink:href="assets/project-icons/check.svg#c"></use>
      </svg>
      {{t('publish')}}
    </button>
  }
</mat-toolbar>

@if (showCloseConfirmation) {
  <app-modal [title]="'areYouSureQ' | transloco" [footerButtons]="modalButtons"
    (closeEvent)="showCloseConfirmation = false">
    <p class="text-color-soft">{{'stopQuestEditingModalM1' | transloco}}</p>
    <p class="text-color-soft">{{'stopQuestEditingModalM2' | transloco}}</p>
  </app-modal>
}

@if (showMapEditor) {
  <app-map-editor
    [title]="('step' | transloco) + ' ' + (mapEditorOptions.mapForStepIndex + 1)"
    [questObjectiveType]="mapEditorOptions.objectiveType"
    [config]="mapEditorConfig"
    [stepFormValues]="stepsFormValues"
    [userGeo]="userGeo"
    (close)="showMapEditor = false"
    (applyClick)="onMapApply($event)"
    >
  </app-map-editor>
}
