@if (form) {
  <form [formGroup]="form" *transloco="let t">
    <ng-container formArrayName="steps">
      @for (fg of getSteps().controls; track fg; let step_i = $index) {
        <div class="form-container single wider" formGroupName="{{ step_i }}">
          <div class="left-side">
            <div class="form-row margin-bottom-24">
              <div class="main-heading-text text-18">{{ t('step') }} {{ step_i + 1 }}</div>
              @if (step_i !== 0) {
                <svg class="regular-img-size pointer" (click)="askToRemoveStep(step_i)"
                     style="color: var(--atlas-secondary-color-step-600)">
                  <use xlink:href="assets/project-icons/trash.svg#c"></use>
                </svg>
              }
            </div>
            <mat-form-field appearance="fill" class="form-field margin-bottom-24" color="accent">
              <mat-label>{{ t('stepName') }}</mat-label>
              <input matInput name="stepName" formControlName="name" maxlength="41" autocomplete="disabled"/>
            </mat-form-field>

            @if (!adaptive) {
              <app-dropdown class="margin-bottom-24" [title]="t('objectiveType') + ' *'"
                            [options]="optionsToChooseFrom" formControlName="objectiveType"></app-dropdown>
            }

            @if (isUserSuperuser) {
              <button
                mat-flat-button
                class="primary-btn margin-bottom-24"
                color="primary"
                (click)="openEncounterEditorForStep(step_i)"
              >
                {{ t('encounterEditor') }}
              </button>

              <button
                mat-flat-button
                class="primary-btn margin-bottom-24"
                color="primary"
                (click)="openPreconditionEditorForStep(step_i)"
              >
                {{ t('preconditionEditor') }}
              </button>
            }

            <mat-form-field appearance="fill" class="form-field mat-text-area margin-bottom-18" color="accent">
              <mat-label>{{ t('task') }}</mat-label>
              <textarea matInput formControlName="task" rows="4" maxlength="2000"></textarea>
            </mat-form-field>

            <div class="form-row">
              <span
                class="usual-flex-center general-big-text link-text"
                [ngClass]="{ disabled: getCurrentImages(step_i).length === this.MAX_NUMBER_OF_IMAGES }"
                (click)="addPhotoClick(step_i)"
              >
                <svg class="regular-img-size">
                  <use xlink:href="assets/project-icons/add.svg#a"></use>
                </svg>
                {{ t('addTaskImage') }}
              </span>
              <span
                class="usual-flex-center general-big-text link-text paste-photo-btn"
                [ngClass]="{ disabled: getCurrentImages(step_i).length === this.MAX_NUMBER_OF_IMAGES }"
                (click)="clickPastePhoto(step_i)"
              >
                <svg class="regular-img-size">
                  <use xlink:href="assets/project-icons/clipboard.svg#c"></use>
                </svg>
                {{ t('pasteTaskImage') }}
              </span>
            </div>
            <div class="photos-row">
              @for (url of getCurrentImages(step_i); track url; let i = $index) {
                <div class="img-thumbnail">
                  <img [src]="url.url ? url.url : url.base" alt=""/>
                  <div class="close-icon-container">
                    <svg class="close-icon pointer" viewBox="0 0 24 24" (click)="removeImgByIndex(step_i, i)">
                      <use xlink:href="assets/project-icons/close.svg#c"></use>
                    </svg>
                  </div>
                </div>
              }
            </div>
            <input id="add-task-imd-input-{{ step_i }}" style="display: none" type="file"
                   accept="image/png, image/jpeg"/>
            <mat-form-field appearance="fill" class="form-field mat-text-area" color="accent">
              <mat-label>{{ t('finalDescription') }}</mat-label>
              <textarea matInput formControlName="finalDescription" rows="4" maxlength="2000"></textarea>
            </mat-form-field>
            <div class="form-row margin-top-18">
              <mat-slide-toggle formControlName="canBeSkipped" color="primary">{{ t('playerCanSkipStep') }}
              </mat-slide-toggle>
              <app-tooltip [paragraphs]="[t('playerCanSkipStepTooltipP1'), t('playerCanSkipStepTooltipP2')]">
              </app-tooltip>
            </div>
          </div>
          <div class="right-side">
            @if (adaptive) {
              <app-adaptive-step-editing-form
                [formData]="getSteps().at(step_i).get('objectivesData')?.value ?? {}"
                (formChange)="getSteps().at(step_i).get('objectivesData').setValue($event)"
              ></app-adaptive-step-editing-form>
            } @else {
              <div class="objectives-container" [ngClass]="hasStepAnyMapData(step_i) ? 'filled' : 'empty'">
                @if (!hasStepAnyMapData(step_i)) {
                  <button
                    mat-flat-button
                    class="primary-btn"
                    color="primary"
                    [disabled]="!getSteps().at(step_i).get('objectiveType')?.value"
                    (click)="requestMapOpen.emit({ stepIndex: step_i, form: this.form.value })"
                  >
                    {{ t('addObjectives') }} *
                  </button>
                  <p class="small-secondary-text">{{ t('addObjectivesDescription') }}</p>
                } @else {
                  <app-display-map [options]="mapsOptions[step_i]" [hideControls]="true"></app-display-map>
                  <div class="edit-button" (click)="requestMapOpen.emit({ stepIndex: step_i, form: this.form.value })">
                    <img src="assets/project-icons/pencil.svg" alt="E"/>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
    </ng-container>
  </form>
}

<div class="fab-container">
  <button class="fab-plus usual-flex-center" (click)="addStep()">
    <img src="assets/project-icons/add.svg" alt="+"/>
  </button>
</div>

@if (editingEncounterStepIndex !== -1) {
  <app-modal [size]="'large'"
             [title]="'encounterEditor' | transloco"
             [ignoreEscape]="true"
             [footerButtons]="encounterEditorModalButtons"
             (closeEvent)="editingEncounterStepIndex = -1">
    <div style="height: 75vh; width: 99%;">
      <ngx-monaco-editor
        style="height: 100%; width: 100%;"
        [options]="editorOptions"
        [(ngModel)]="encounterDataToEdit">
      </ngx-monaco-editor>
    </div>
  </app-modal>
}

@if (editingPreconditionStepIndex !== -1) {
  <app-modal [size]="'large'"
             [title]="'preconditionEditor' | transloco"
             [ignoreEscape]="true"
             [footerButtons]="preconditionEditorModalButtons"
             (closeEvent)="editingPreconditionStepIndex = -1">
    <div style="height: 75vh; width: 99%;">
      <ngx-monaco-editor
        style="height: 100%; width: 100%;"
        [options]="editorOptions"
        [(ngModel)]="preconditionDataToEdit">
      </ngx-monaco-editor>
    </div>
  </app-modal>
}

@if (stepToRemove !== -1) {
  <app-modal [title]="'areYouSureQ' | transloco" [footerButtons]="modalButtons" (closeEvent)="stepToRemove = -1">
    <p class="text-color-soft">{{ 'deleteStepQ' | transloco }}</p>
  </app-modal>
}
