<ng-container *transloco="let t">
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="form-field margin-bottom-24" color="accent">
      <mat-label>{{t('minDistFromLastMarker')}}</mat-label>
      <input matInput type="number" name="minDistFromLastMarker" formControlName="minDistFromLastMarker" autocomplete="disabled"/>
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-field" color="accent">
      <mat-label>{{t('maxDistFromLastMarker')}}</mat-label>
      <input matInput type="number" name="maxDistFromLastMarker" formControlName="maxDistFromLastMarker" autocomplete="disabled"/>
    </mat-form-field>
    <app-collapsable-section [title]="t('advanced')">
      <div class="form-row margin-bottom-12">
        <mat-slide-toggle formControlName="stickToWays" color="primary">{{t('stickToWays')}}</mat-slide-toggle>
        <!--                  <app-tooltip [paragraphs]="[t('playerCanSkipStepTooltipP1'), t('playerCanSkipStepTooltipP2')]">-->
        <!--                  </app-tooltip>-->
      </div>
      <div class="form-row">
        <mat-slide-toggle formControlName="stickToAmenities" color="primary">{{t('stickToAmenities')}}</mat-slide-toggle>
      </div>
    </app-collapsable-section>
  </form>
</ng-container>
