import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {environment} from '../../../environments/environment';
import {QuestPublicationRequest} from 'quest-atlas-angular-components';

@Injectable({
  providedIn: 'root'
})
export class QuestPublishRequestsService implements OnDestroy{

  private destroy$ = new Subject<void>();

  constructor(private http: HttpClient) { }

  getQuestPublishRequests$(): Observable<QuestPublicationRequest[]> {
    return this.http.get<any[]>(`${environment.API_URL}/quests/publish-requests`)
      .pipe(
        map((value) => {
          return value.map((request) => {
            return {
              id: request.id,
              status: request.status,
              questId: request.quest_id,
              createdAt: new Date(request.created_at),
              updatedAt: new Date(request.updated_at)
            };
          });
        }),
        takeUntil(this.destroy$)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
