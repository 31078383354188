import robustPointInPolygon from 'robust-point-in-polygon';
import * as turf from '@turf/turf'
import {lengthToDegrees} from '@turf/helpers';
import {GeoCoords} from '../interfaces/geo-and-movement.interfaces';

export function checkIfInThePolygon(position: GeoCoords, polygon: GeoCoords[]): boolean {
  const coordinates: [number, number][] = polygon.map(c => [c.lat, c.lng]);

  return robustPointInPolygon(coordinates, [position.lat, position.lng]) < 1;
}

export function nMetersFromCoords(coords: GeoCoords, meters: number, heading: 'n' | 'e' | 's' | 'w'): GeoCoords {
  const angle: number = {n: 0, e: 90, s: 180, w: 270}[heading];
  const newCoords = turf.getCoord(turf.transformTranslate(turf.point([coords.lng, coords.lat]), meters, angle, {units: 'meters'}));
  return {lng: newCoords[0], lat: newCoords[1]};
}

export function randomCoordsInRadius(circleCenter: GeoCoords, radius: number): GeoCoords {
  const a = lengthToDegrees(radius, 'meters');

  // we first random value in (-0.5, 0,5), then convert it to the range of (-1, 1) and then multiplying to a radius
  const lngDelta = (Math.random() - 0.5) * 2 * a;
  const latDelta = (Math.random() - 0.5) * 2 * a;

  return {lng: circleCenter.lng + lngDelta, lat: circleCenter.lat + latDelta};
}

export function distanceBetweenCoordinates(coords: GeoCoords[]): number {
  let distance = 0;

  for (let i = 1; i < coords.length; i++) {
    const p1 = coords[i - 1];
    const p2 = coords[i];

    const point1 = turf.point([p1.lng, p1.lat]);
    const point2 = turf.point([p2.lng, p2.lat]);

    distance += turf.distance(point1, point2, {units: 'kilometers'});
  }

  return distance;
}
