export enum QuestStepObjectiveType {
  markerReach = 'markerReach',
  markerFind = 'markerFind',
  markerChase = 'markerChase',
  surviveForTime = 'surviveForTime',
  serpentScalesAlgorithm = 'serpentScalesAlgorithm', //this is more advanced algorithm, where a quest master basically can do anything
  manualApproval = 'manualApproval',
  adaptive = 'adaptive'
}

export enum QuestAccessLevel {
  public = 'public',
  own = 'own',
  collaborator = 'collaborator',
  player = 'player'
}
