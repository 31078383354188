import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {CachingService} from '../../services/caching.service';
import {MY_CHAINS_PATH} from '../../app-routing.module';
import {Subject, take, takeUntil} from 'rxjs';
import {ModalButton} from '../../common-components/modal/modal.component';
import {FormControl} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {MatIconButton} from '@angular/material/button';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  isInChainsView = false;
  showDeleteConfirmation = signal(false);
  deleteAllQuests = new FormControl(false);

  isUserSuperuser = false;

  modalButtons: ModalButton[] = [];

  @ViewChild('menuButtonTrigger') menuButtonTrigger: MatIconButton;

  private destroy$ = new Subject<void>();

  constructor(private authService: AuthService,
              private router: Router,
              private cachingService: CachingService,
              private activatedRoute: ActivatedRoute,
              private transloco: TranslocoService,
              private userService: UserService) {
    // this.activatedRoute.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment) => {
    //   console.log('here', fragment);
    // });
  }

  ngOnInit(): void {
    this.userService.getUserInfo$().pipe(take(1), takeUntil(this.destroy$)).subscribe((user) => {
      this.isUserSuperuser = user.isSuperUser;
    });

    this.modalButtons = [
      {
        type: 'secondary',
        text: this.transloco.translate('stay'),
        onClick: () => (this.showDeleteConfirmation.set(false))
      },
      {
        type: 'error',
        text: this.transloco.translate('delete'),
        onClick: () => this.deleteUser()
      }
    ];

    this.checkIfInChainsView(this.router.url);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        this.checkIfInChainsView(event.url);
      }
    });

    this.cachingService.clearChainEditCache();
  }

  ngAfterViewInit(): void {
    this.activatedRoute.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment) => {
      if (fragment === 'user-modal') {
        this.menuButtonTrigger._elementRef.nativeElement.click();
        this.router.navigate([], {fragment: null});
      }
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  private deleteUser(): void {
    this.userService.deleteUser$(this.deleteAllQuests.value).subscribe(() => {
      this.showDeleteConfirmation.set(false);
      this.logout();
    });
  }

  private checkIfInChainsView(url: string) {
    this.isInChainsView = url.includes(MY_CHAINS_PATH);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
