<div class="active-chip general-text" [ngClass]="getCssClasses()">
  <div class="display-flex-centered">
    <span class="text">
      {{text}}
    </span>
    <svg class="close" viewBox="0 0 20 20" width="16" height="16" (click)="closeClick($event)">
      <use xlink:href="assets/icons/circle-close-b.svg#c"></use>
    </svg>
  </div>
</div>
