import {Injectable} from '@angular/core';
import {FacebookLoginProvider, SocialAuthService, SocialUser} from "@abacritt/angularx-social-login";
import {from, Observable, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {UserAuthResponse} from "./types";
import {switchMap} from "rxjs/operators";

@Injectable()
export class AuthService {
  lastTokenUpdate = new Date();
  private readonly API_URL_AUTH = `${environment.API_URL}/auth`;

  constructor(
    private httpClient: HttpClient,
    private socialAuthService: SocialAuthService
  ) { }

  get authState(): Observable<SocialUser> {
    return this.socialAuthService.authState
  }

  signInFacebook(): Observable<UserAuthResponse> {
    return from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      switchMap(({authToken}) =>
        this.httpClient.post<UserAuthResponse>(`${this.API_URL_AUTH}/facebook/`, {access_token: authToken})),
      tap(authRes => {
        AuthService.updateTokens(authRes.access_token, authRes.refresh_token)
      })
    )
  }

  login({email, password}: {email: string, password: string}): Observable<UserAuthResponse> {
    return this.httpClient.post<UserAuthResponse>(`${this.API_URL_AUTH}/login/`, {username: email, password}).pipe(
      tap(authRes => {
        AuthService.updateTokens(authRes.access_token, authRes.refresh_token);
      })
    );
  }

  signUp({username, email, password}: {username: string, email: string, password: string}): Observable<void> {
    return this.httpClient.post<void>(`${this.API_URL_AUTH}/registration/`,
      {username, email, password1: password, password2: password});
  }

  refreshToken(): Observable<{access: string; refresh?: string}> {
    const refreshToken = localStorage.getItem('refreshToken');
    return this.httpClient.post<{access: string; refresh?: string}>(`${this.API_URL_AUTH}/token/refresh/`,
      {refresh: refreshToken}).pipe(
      tap(authRes => (AuthService.updateTokens(authRes.access, authRes.refresh)))
    );
  }
  changePasswordFromRecovery(userId: string, token: string, newPassword: string, repeatPassword: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.httpClient.post(`${this.API_URL_AUTH}/password/reset/confirm/`,
      {new_password1: newPassword, new_password2: repeatPassword, uid: userId, token});
  }

  logout() {
    // api call
    this.socialAuthService.signOut();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  private static updateTokens(access?: string, refresh?: string): void {
    if (!access) {
      return;
    }

    localStorage.setItem('accessToken', access);
    if (refresh) {
      localStorage.setItem('refreshToken', refresh);
    }
  }
}
