import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';

export interface ModalButton {
  type: 'primary' | 'secondary' | 'error';
  text: string;
  onClick: () => void;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  imports: [
    NgClass
],
  standalone: true
})
export class ModalComponent implements OnInit {
  @Input() title = '';
  @Input() footerButtons: ModalButton[] = [];
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() ignoreEscape = false;

  @Output() closeEvent = new EventEmitter<void>();

  @HostListener('document:keyup', ['$event'])
  keyup(event: any) {
    if (event.key === 'Escape' && !this.ignoreEscape) this.closeEvent.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
