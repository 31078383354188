import {
  getBrowserLang,
  provideTransloco,
  TranslocoModule
} from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import {environment} from '../environments/environment';

const availableLangs = ['en', 'uk'];

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
      provideTransloco({
        config: {
          availableLangs,
          defaultLang: availableLangs.includes(getBrowserLang()) ? getBrowserLang() : 'en',
          missingHandler: {
            useFallbackTranslation: true,
          },
          fallbackLang: 'en',
          // Remove this option if your application doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: environment.production,
        },
        loader: TranslocoHttpLoader
      }),
  ],
})
export class TranslocoRootModule {}
