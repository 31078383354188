import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { QuestDropdownValues } from '../../../common-components/dropdown/dropdown.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormComponent, GeneralFormData } from '../form-component.interface';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { debounceTime, filter, Subject, takeUntil } from 'rxjs';
import {
  updateFormValuesObjectFromIDB
} from '../map-editor/form-component-general.functions';
import {getPrivacyOptions, getQuestTypeOptions, getRecommendedForOptions} from '../dropdown-options-for-forms';
import {IDB_NAME, IDB_VERSION} from '../quest-editor.constants';
import {UserService} from '../../../services/user.service';
import {User} from 'quest-atlas-angular-components';
import {DomSanitizer} from '@angular/platform-browser';
import {idbGeneralFormStore} from '../quest-editor.functions';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY'
  }
};

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class GeneralFormComponent implements OnInit, FormComponent, OnDestroy {
  readonly type = 'general';

  questTypeOptions: QuestDropdownValues[] = getQuestTypeOptions();

  privacyOptions: QuestDropdownValues[] = getPrivacyOptions();

  recommendedForOptions: QuestDropdownValues[] = getRecommendedForOptions();

  organizationsOptions: QuestDropdownValues[] = [];

  user: User;

  @Input() adaptive = false;

  // @ts-ignore
  @Input() formValues!: GeneralFormData;

  form: FormGroup;
  db: IDBDatabase;

  @Input() cacheFormData = true;

  destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private userService: UserService, private ds: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.formValues) {
      this.formValues = {};
    }

    this.userService.getUserInfo$().pipe(takeUntil(this.destroy$)).subscribe(user => {
      // we currently won't support the case, when user was kicked from the organization

      this.user = user;

      this.organizationsOptions = user.organizations.map(org => {
        const opt = {
          title: org.name,
          value: org.id
        }

        if (org.secondaryLogo.startsWith('http')) {
          opt['imgUrl'] = org.secondaryLogo;
        } else {
          opt['imgSvg'] = this.ds.bypassSecurityTrustHtml(org.secondaryLogo);
        }

        return opt;
      });

      if (this.cacheFormData) {
        const request = window.indexedDB.open(IDB_NAME, IDB_VERSION);

        request.onerror = (event) => {
          console.error('IndexedDB error:', event);
        };
        request.onsuccess = (event) => {
          this.db = (event.target as any).result;

          updateFormValuesObjectFromIDB(this.formValues, idbGeneralFormStore(this.adaptive), this.db).pipe(takeUntil(this.destroy$)).subscribe({
            next: lsv => {
              this.formValues = lsv;

              this.initForm();
            },
            error: () => {
              this.initForm();
            }
          });
        };
      } else {
        this.initForm();
      }
    });
  }

  private initForm(): void {
    if (!this.formValues.mode) {
      this.formValues.mode = this.questTypeOptions[0];
    }

    this.form = this.fb.group({
      name: [this.formValues.name, [Validators.required]],
      mode: [this.formValues.mode, [Validators.required]],
      recommendedFor: [this.formValues.recommendedFor, [Validators.required]],
      privacy: [this.privacyOptions[1], [Validators.required]],
      organizationId: [this.formValues.organizationId],
    });

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.cacheFormData),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        if (this.db) {
          const objectStore = this.db.transaction(idbGeneralFormStore(this.adaptive), 'readwrite').objectStore(idbGeneralFormStore(this.adaptive));
          objectStore.clear();
          objectStore.put(value);
        }
      });
  }

  isFormValid(): boolean {
    return !!this.form?.valid;
  }

  submitForm(): any {
    return this.form.value;
  }

  getFormData(): any {
    return this.form.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
