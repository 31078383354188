import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslocoDirective} from '@ngneat/transloco';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs';

@Component({
  selector: 'app-embedded-map',
  standalone: true,
  imports: [
    MatToolbarModule,
    TranslocoDirective
  ],
  templateUrl: './embedded-map.component.html',
  styleUrl: './embedded-map.component.scss'
})
export class EmbeddedMapComponent implements OnInit {
  url: SafeResourceUrl;

  constructor(private ds: DomSanitizer, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      let coreUrl = environment.embeddableUrl

      if (params['quest']) {
        coreUrl = `${coreUrl}?quest=${params['quest']}`;
      } else if (params['place']) {
        coreUrl = `${coreUrl}?place=${params['place']}`;
      }

      this.url = this.ds.bypassSecurityTrustResourceUrl(coreUrl);
    });
  }
}
