import {QuestStepObjectiveType} from './quest-core.enums';
import {QGraph, QGraphVertex, QGraphVertexWithCircle} from './geo-and-movement.interfaces';

export interface QuestStepObjectiveMetadata {
  type: QuestStepObjectiveType;
}

export interface QuestStepObjectiveMetadataMarkerReach extends QuestStepObjectiveMetadata{
  marker: QGraphVertex;
}

// eslint-disable-next-line arrow-body-style
export const isTypeMarkerReach = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataMarkerReach => {
  return obj.type === QuestStepObjectiveType.markerReach;
};

export interface QuestStepObjectiveMetadataMarkerFind extends QuestStepObjectiveMetadata{
  marker: QGraphVertexWithCircle;
  //In meters. If the user is this radius from marker, it will be revealed on the map, but the user still has to reach it
  revealInRadius?: number;
}

// eslint-disable-next-line arrow-body-style
export const isTypeMarkerFind = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataMarkerFind => {
  return obj.type === QuestStepObjectiveType.markerFind;
};

export interface QuestStepObjectiveMetadataMarkerChase extends QuestStepObjectiveMetadata{
  pathGraph: QGraph<{state: 'start' | 'middle' | 'end'}, {speed?: number}>;
  speed: number; //km/h
  showPath?: boolean;
}

// eslint-disable-next-line arrow-body-style
export const isTypeMarkerChase = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataMarkerChase => {
  return obj.type === QuestStepObjectiveType.markerChase;
};

export interface QuestStepObjectiveMetadataSurviveForTime extends QuestStepObjectiveMetadata{
  //there will bbe markers on a map, which when you catch, will decrease the amount time you have to survive
  //in seconds
  markersGraph: QGraphVertex<{timeDecrease: number}>;
}

// eslint-disable-next-line arrow-body-style
export const isTypeSurviveForTime = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataSurviveForTime => {
  return obj.type === QuestStepObjectiveType.surviveForTime;
};

//not a priority for MVP
export interface QuestStepObjectiveMetadataSerpentScalesAlgorithm extends QuestStepObjectiveMetadata{
  algorithm: any;
//  here will be more fields
}

// eslint-disable-next-line arrow-body-style
export const isTypeSerpentScalesAlgorithm = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataSerpentScalesAlgorithm => {
  return obj.type === QuestStepObjectiveType.serpentScalesAlgorithm;
};

//TODO maybe this type can also be used for QR code scanning
export interface QuestStepObjectiveMetadataManualApproval extends QuestStepObjectiveMetadata{
  requiredArtifacts: Array<'gps' | 'photo'>;
//  here will be more fields
}

// eslint-disable-next-line arrow-body-style
export const isTypeSerpentManualApproval = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataManualApproval => {
  return obj.type === QuestStepObjectiveType.manualApproval;
};

export interface QuestStepObjectiveMetadataAdaptive extends QuestStepObjectiveMetadata{
  minDistFromLastMarker: number;
  maxDistFromLastMarker: number;
  skipWays?: ('unclassified'|'footway'|'cycleway'|'steps'|'path'|'service'|'tertiary'|'track'|'residential')[]
  skipAmenities?: string[];
  stickToWays?: boolean;
  stickToAmenities?: boolean;
}

// eslint-disable-next-line arrow-body-style
export const isTypeAdaptive = (obj: QuestStepObjectiveMetadata): obj is QuestStepObjectiveMetadataAdaptive => {
  return obj.type === QuestStepObjectiveType.adaptive;
};


//TODO доробити отут дані для різних типів обджектівів. І тоді далі по сервісах
