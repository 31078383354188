import {Component, EventEmitter, input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {TranslocoDirective} from '@ngneat/transloco';
import {
  CollapsableSectionComponent
} from 'quest-atlas-angular-components';
import {QuestStepObjectiveType} from 'quest-atlas-angular-components';

export interface AdaptiveStepEditingFormData {
  type: QuestStepObjectiveType.adaptive;
  minDistFromLastMarker?: number;
  maxDistFromLastMarker?: number;
  stickToWays?: boolean;
  stickToAmenities?: boolean;
}

@Component({
  selector: 'app-adaptive-step-editing-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CollapsableSectionComponent,
    MatFormField,
    MatInput,
    MatLabel,
    MatSlideToggle,
    TranslocoDirective
  ],
  templateUrl: './adaptive-step-editing-form.component.html',
  styleUrl: './adaptive-step-editing-form.component.scss'
})
export class AdaptiveStepEditingFormComponent implements OnInit, OnDestroy {
  formData = input<AdaptiveStepEditingFormData>({type: QuestStepObjectiveType.adaptive});
  form: FormGroup;

  @Output() formChange = new EventEmitter<AdaptiveStepEditingFormData>();

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.formData().stickToWays === undefined) {
      this.formData().stickToWays = true;
    }
    if (!this.formData().type) {
      this.formData().type = QuestStepObjectiveType.adaptive;
    }

    this.form = this.fb.group({
      type: [QuestStepObjectiveType.adaptive],
      minDistFromLastMarker: [this.formData().minDistFromLastMarker],
      maxDistFromLastMarker: [this.formData().maxDistFromLastMarker],
      stickToWays: [this.formData().stickToWays],
      stickToAmenities: [this.formData().stickToAmenities]
    });

    this.formChange.emit(this.form.value);

    this.form.valueChanges.pipe(
      debounceTime(300),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      if (!this.form.value.type) {
        this.form.patchValue({ type: QuestStepObjectiveType.adaptive });
      }
      if (!this.form.value.stickToWays && !this.form.value.stickToAmenities) {
        this.form.patchValue({ stickToWays: true });
      }

      this.formChange.emit(this.form.value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
