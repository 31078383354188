import {ResolveFn} from '@angular/router';
import {filter, merge, Observable, of, take, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';

export const waitForTranslationToLoadResolver = (translationLoaded$: Observable<boolean>) => {
  return (() => {
    return merge(
      timer(3000).pipe(switchMap(() => of(true))),
      translationLoaded$
    ).pipe(filter((loaded: boolean) => loaded), take(1));
  } )as ResolveFn<unknown>
};
