export enum ObeliskType {
  stationary = 'stationary',
}

export enum ObeliskImportance {
  small = 'small',
  medium = 'medium',
  big = 'big',
  national = 'national',
  world = 'world',
}
