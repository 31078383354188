<mat-toolbar class="toolbar top element-shadow" *transloco="let t">
  <img alt="broken" src="assets/vectors/logo.svg">

  <div class="bar-btn-container">
    @if (isInChainsView) {
      <a class="primary-bar-btn" routerLink="/quests-chains/new">
        <svg class="pointer" viewBox="0 0 24 24" width="18" height="18">
          <use [attr.xlink:href]="'../../../../assets/icons/plus.svg#s'"></use></svg
          >{{t('createNewChain')}}
        </a>
      }

      @if (!isInChainsView) {
        <button [matMenuTriggerFor]="questCreateChooser" class="primary-bar-btn">
          <svg class="pointer" viewBox="0 0 24 24" width="18" height="18">
            <use [attr.xlink:href]="'../../../../assets/icons/plus.svg#s'"></use>
          </svg>
          {{t('createNewQuest')}}
        </button>
        <mat-menu #questCreateChooser="matMenu">
          <a mat-menu-item routerLink="/quest-editor/new">
            <div class="display-flex-centered">
              {{t('regularQuest')}}
            </div>
          </a>
          @if (isUserSuperuser) {
            <a mat-menu-item routerLink="/quest-editor/adaptive/new">
              <div class="display-flex-centered">
                {{t('adaptiveQuest')}}
              </div>
            </a>
          }
        </mat-menu>
      }

      <button mat-icon-button
        #menuButtonTrigger
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showDeleteConfirmation.set(true)">
          <div class="color-red display-flex-centered">
            <svg class="pointer regular-img-size margin-right-12">
              <use [attr.xlink:href]="'../../../../assets/project-icons/trash.svg#c'"></use>
            </svg>
            <span>{{t('deleteAccount')}}</span>
          </div>
        </button>
        <button mat-menu-item (click)="logout()">
          <div class="color-grey-700 display-flex-centered">
            <svg class="pointer regular-img-size margin-right-12">
              <use [attr.xlink:href]="'../../../../assets/project-icons/logout.svg#c'"></use>
            </svg>
            <span>{{t('logout')}}</span>
          </div>
        </button>
      </mat-menu>
      <!--    <button class="secondary-bar-btn" (click)="logout()">Log Out</button>-->
    </div>
  </mat-toolbar>

  <div class="home-container">
    <div class="home-sidebar">
      <app-sidebar-nav></app-sidebar-nav>
    </div>
    <div class="quest-list">
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (showDeleteConfirmation()) {
    <app-modal [title]="'deleteAccountQ' | transloco" [footerButtons]="modalButtons"
      (closeEvent)="showDeleteConfirmation.set(false); deleteAllQuests.setValue(false)">
      <div class="text-color-soft margin-top-12">{{'delAcc1' | transloco}}</div>
      <div class="text-color-soft">{{'delAcc2' | transloco}}</div>
      <p><mat-checkbox [formControl]="deleteAllQuests" color="warn"><span class="text-color-soft">{{'delAcc3' | transloco}}</span></mat-checkbox></p>
    </app-modal>
  }
