import { Injectable } from '@angular/core';
import {QuestsChain} from '../pages/quests-chains/interfaces/quests-chains.core.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CachingService {

  readonly CHAINS_EDIT_CACHE = 'chains-edit-cache';

  constructor() { }

  cacheChainEditChanges(chain: QuestsChain): void {
    localStorage.setItem(this.CHAINS_EDIT_CACHE, JSON.stringify(chain));
  }

  clearChainEditCache(): void {
    localStorage.removeItem(this.CHAINS_EDIT_CACHE);
  }

  getChainEditCache(): QuestsChain {
    const chain = localStorage.getItem(this.CHAINS_EDIT_CACHE);
    if (!chain) {
      return null;
    }

    return JSON.parse(chain);
  }
}
