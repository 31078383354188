  import {CalendarDate, TimeSpan} from '../interfaces/common.interfaces';
import {translateObject} from '@ngneat/transloco';

export function toEUCalendarDateString(date: CalendarDate): string {
  let day: number | string = date.day;

  if (day < 10) {
    day = `0${day}`;
  }

  let month: number | string = date.month + 1;

  if (month < 10) {
    month = `0${month}`
  }

  return `${day}.${month}.${date.year}`;
}

export function toEUDateString(date: Date): string {
  let day: number | string = date.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  let month: number | string = date.getMonth() + 1;

  if (month < 10) {
    month = `0${month}`
  }

  return `${day}.${month}.${date.getFullYear()}`;
}

export function timeToPrettyText(timeSpan: TimeSpan): string {
  if (!timeSpan) {
    return '-';
  }

  let res = '';

  if (timeSpan.days) {
    res += `${timeSpan.days}${translateObject('dt.d')} `;
  }

  if (timeSpan.hours) {
    res += `${timeSpan.hours}${translateObject('dt.h')} `;
  }

  if (timeSpan.minutes) {
    res += `${(timeSpan.minutes < 10 ? '0' : '')}${timeSpan.minutes}${translateObject('dt.m')} `;
  }

  if (timeSpan.seconds) {
    res += `${(timeSpan.seconds < 10 ? '0' : '')}${timeSpan.seconds}${translateObject('dt.s')} `;
  }

  return res;
}

export function secondsToTime(sec: number, round = false): TimeSpan {
  if (!sec) {
    return null;
  }

  if (round) {
    // Round the seconds to the nearest 10 minutes
    let roundBy = 600;

    if (sec < 600) {
      roundBy = 60;
    } else if (sec < 60) {
      roundBy = 1;
    }

    sec = Math.round(sec / roundBy) * roundBy;
  }

  const s = sec % 60;
  sec -= s;
  let totalM = sec / 60;
  const m = totalM % 60;
  totalM -= m;
  const h = totalM / 60;

  return {hours: h, minutes: m, seconds: s};
}


export function timeToSeconds(time: TimeSpan): number {
  let s = 0;

  if (time.days) {
    s += time.days * 86400;
  }

  if (time.hours) {
    s += time.hours * 3600;
  }

  if (time.minutes) {
    s += time.minutes * 60;
  }

  if (time.seconds) {
    s += time.seconds;
  }

  return s;
}

export function datetimeToPrettyString(date: Date): string {
  if (!date) {
    return '-';
  }

  return `${toEUDateString(date)} ${date.toLocaleTimeString()}`;
}
