import {GenericZoneEditEngine} from '../quest-editor/map-editor/generic-zone-edit-engine.class';
import * as mapboxgl from 'mapbox-gl';
import {QGraph} from 'quest-atlas-angular-components';

export class ObeliskZoneEditEngine extends GenericZoneEditEngine {
  constructor(map: mapboxgl.Map,
              zone?: QGraph,
              callbackOnAnyClick?: (zoneEngine: GenericZoneEditEngine) => void) {
    super(map, null,'start-zones', 'start-zone-outline', null, zone, callbackOnAnyClick);
  }

  getStrokeColor(): string {
    return '#34C759';
  }

}
