import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal} from '@angular/core';
import {finalize, Subject, take, takeUntil} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalSpinnerService} from '../../services/global-spinner.service';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {QuestMetadata} from 'quest-atlas-angular-components';
import {switchMap} from 'rxjs/operators';
import {QuestAccessLevel} from 'quest-atlas-angular-components';

@Component({
  selector: 'app-quest-accept-share',
  templateUrl: './quest-accept-share.component.html',
  styleUrls: ['./quest-accept-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestAcceptShareComponent implements OnInit, OnDestroy {

  quest = signal<QuestMetadata>(null);
  private destroy$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
              public globalSpinnerService: GlobalSpinnerService,
              public questsCrudService: QuestsCrudService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.globalSpinnerService.showSpinner();
    this.activatedRoute.params.pipe(take(1), takeUntil(this.destroy$)).subscribe((params) => {
      this.questsCrudService.submitShareToken(params['shareCode']).pipe(
        switchMap(value => {
          return this.questsCrudService.loadQuestById(value.questId);
        }),
        finalize(() => {
          this.globalSpinnerService.hideSpinner();
        })
      ).subscribe((quest) => {
        this.quest.set(quest);
      })
    });
  }

  navigateToEditor(quest: QuestMetadata) {
    this.router.navigate([`/quest-editor/${quest.id}`]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly QuestAccessLevel = QuestAccessLevel;
}
