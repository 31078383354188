import {Component, Input, OnInit} from '@angular/core';
import {QuestMetadata} from 'quest-atlas-angular-components';

@Component({
  selector: 'app-publish-view',
  templateUrl: './publish-view.component.html',
  styleUrls: ['./publish-view.component.scss']
})
export class PublishViewComponent implements OnInit {
  @Input() quest!: QuestMetadata;

  constructor() { }

  ngOnInit(): void {}

}
