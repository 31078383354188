<div class="odm-container" [ngClass]="{'selected': open}" *transloco="let t" (click)="click()">
  <div class="odm-header">
    <div>
      <div class="odm-type bold-16" [ngClass]="odm.type">{{getType()}}</div>
      <div class="regular-16">
        {{t('from')}}: {{odm.sender}}
      </div>
    </div>
    <div class="regular-14">
      {{odm.createdAt | date}}
      @if (!odm.isRead) {
        <div class="bold-14 color-red margin-left-4">{{t('newMessageStatus')}}!</div>
      }
    </div>
  </div>
  @if (open) {
    <div class="odm-body">
      @if (odm.message) {
        <div class="odm-message regular-14 secondary-text-color margin-bottom-8">
          {{odm.message}}
        </div>
      }
      <div class="odm-type-specific semibold-14">
        @switch (odm.type) {
          @case ('bad_location_report') {
            {{t('reason')}}: <span class="regular-14 margin-right-4">{{t('blrReasons.' + odm.suppliedData['reason'])}}</span>
            {{t('quest')}}: <span class="regular-14 margin-right-4">{{(typeSpecificData$ | async).quest.name}}</span>
            {{t('step')}}: <span class="regular-14 margin-right-4">{{(typeSpecificData$ | async).step.name}}</span>
          }
        }
      </div>
    </div>
  }
</div>
