import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'q-svg-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-wrapper.component.html',
  styleUrl: './svg-wrapper.component.scss'
})
export class SvgWrapperComponent {
  @Input() iconPath!: string;
  @Input() iconId = 'c';

  // null, because if an attribute is null, it will not be added at all
  @Input() viewBox = null;
  @Input() width = null;
  @Input() height = null;

  constructor() { }

  ngOnInit() {}

  svgPath(): string {
    return `assets/${this.iconPath}.svg#${this.iconId}`;
  }

  getStyles() {
    return {
      width: this.width ? this.width : '24px',
      height: this.height ? this.height : '24px',
    };
  }
}
