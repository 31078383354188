import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestPublishingService {
  readonly ROOT_URL = `${environment.API_URL}/quests`;

  constructor(private http: HttpClient) { }

  createPublishRequest(quest_id: number): Observable<any> {
    return this.http.post<any>(`${this.ROOT_URL}/create-publish-request/${quest_id}/`, {});
  }

  rejectPublishRequest(request_id: number): Observable<void> {
    return this.http.put<void>(`${this.ROOT_URL}/reject-publish-request/${request_id}`, {});
  }

  approvePublishRequest(request_id: number): Observable<void> {
    return this.http.put<void>(`${this.ROOT_URL}/approve-publish-request/${request_id}`, {});
  }
}
