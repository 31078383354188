import {QGraph, QGraphVertexWithCircle, QGraphVertexWithRadius} from '../interfaces/geo-and-movement.interfaces';
import * as mapboxgl from 'mapbox-gl';
import {isObeliskZoneCircle, Obelisk} from '../interfaces/exploration.interfaces';
import {ObeliskImportance} from '../interfaces/obelisks.enums';

export const obelisksToZones = (obelisks?: Obelisk[]): (QGraph | QGraphVertexWithCircle | QGraphVertexWithRadius)[] => {
  return obelisks.filter(value => !!value).map((obelisk) => {
    if (!isObeliskZoneCircle(obelisk.zone)) {
      return obelisk.zone.absolutePolygon;
    }

    return {
      coords: obelisk.location,
      data: {radius: obelisk.zone.radius}
    } as QGraphVertexWithRadius;
  });
}

export const drawObelisk = (obelisk: Obelisk, map: mapboxgl.Map, onClick = () => {}): mapboxgl.Marker => {
  if (!map) {
    return undefined;
  }

  let dimensions = 42;
  let size = 'big';


  switch (obelisk.importance) {
    case ObeliskImportance.small:
      dimensions = 28;
      size = 'small';
      break;
    case ObeliskImportance.medium:
      dimensions = 36;
      size = 'medium';
      break;
  }
  const div = document.createElement('div');
  div.className = 'map-obelisk-marker-container ' + size;

  const mainBody = document.createElement('div');
  mainBody.className = 'main-body';
  div.appendChild(mainBody);

  const pin = document.createElement('div');
  pin.className = 'pin';
  div.appendChild(pin);

  if (obelisk.svg.includes('svg')) {
    obelisk.svg = obelisk.svg.replace('width="24" height="24"', `width="${dimensions}" height="${dimensions}"`);

    const svg = document.createElement('div');
    svg.style.width = `${dimensions}px`;
    svg.style.height = `${dimensions}px`;
    svg.innerHTML = obelisk.svg;

    mainBody.appendChild(svg);
  } else {
    const img = document.createElement('img');

    img.src = `assets/exploration-icons/${obelisk.svg}.svg`;
    img.width = dimensions;
    img.height = dimensions;

    mainBody.appendChild(img);
  }

  // if (obelisk.userProgress) {
  //   div.className += ' visited';
  // }

  const marker = new mapboxgl.Marker(div).setLngLat(obelisk.location).addTo(map);
  mainBody.addEventListener('click', (ev: any) => {
    onClick();

    ev.stopPropagation();
  });

  return marker;
}
