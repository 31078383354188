import { inject, Injectable } from "@angular/core";
import { Translation, TranslocoLoader } from "@ngneat/transloco";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, tap} from 'rxjs';

export const translationLoaded$ = new BehaviorSubject(false)

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`).pipe(tap(() => {
          translationLoaded$.next(true)
        }));
    }
}
