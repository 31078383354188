import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ODM} from 'quest-atlas-angular-components';
import {TranslocoService} from '@ngneat/transloco';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {map, Observable, of, shareReplay, Subject, takeUntil} from 'rxjs';
import {ODMService} from '../../../services/odm.service';

@Component({
  selector: 'app-odm',
  templateUrl: './odm.component.html',
  styleUrls: ['./odm.component.scss']
})
export class OdmComponent implements OnInit, OnDestroy{
  @Input() odm: ODM;

  open = false;
  typeSpecificData$: Observable<any>;

  private destroy$ = new Subject<void>();

  constructor(private transloco: TranslocoService, private questsService: QuestsCrudService, private odmService: ODMService) {
  }

  ngOnInit(): void {
    this.typeSpecificData$ = this.typeSpecificDataCall$();
  }


  getType(): string {
    switch (this.odm.type) {
      case "bad_location_report":
        return this.transloco.translate('badLocationReportPretty');
      case "quest_publication_approved":
        return this.transloco.translate('questPublicationApprovedPretty');
      case "quest_publication_rejected":
        return this.transloco.translate('questPublicationRejectedPretty');
      default:
        return '-'
    }
  }

  typeSpecificDataCall$(): Observable<any> {
    if (this.odm.type !== 'bad_location_report') {
      return of({});
    }

    return this.questsService.loadQuestById(this.odm.suppliedData['quest_id']).pipe(
      map((quest) => {
        return {
          reason: this.odm.suppliedData['reason'],
          quest,
          step: quest.steps.find((step) => step.id === this.odm.suppliedData['step_id']),
        }
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
  }

  click(): void {
    this.open = !this.open;

    if (!this.odm.isRead) {
      this.odmService.markAsRead$(this.odm.id).pipe(takeUntil(this.destroy$)).subscribe(() => this.odm.isRead = true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

//   TODO add possibility to delete odm
}
