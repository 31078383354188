import {Observable} from 'rxjs';

export const readImageFromClipboard = (): Observable<Blob | null> => new Observable<Blob | null>(subscriber => {
  navigator.clipboard
    .read().then(value => {
    for (const type of value[0].types) {
      if (type.startsWith("image/")) {
        value[0].getType(type).then((imageBlob) => {
          subscriber.next(imageBlob);
          subscriber.complete()
        });

        return;
      }
    }

    subscriber.next(null);
    subscriber.complete();
  }).catch(reason => {
    const error = {msg: 'paste from clipboard.svg failed with error', reason};
    console.error(error);
    subscriber.error(error);
  })
});
