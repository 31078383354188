import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {finalize, Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalSpinnerService} from '../../services/global-spinner.service';
import {QuestMetadata} from 'quest-atlas-angular-components';
import {QuestPublishingService} from '../../services/quest-publishing.service';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-quest-single-page',
  templateUrl: './quest-single-page.component.html',
  styleUrls: ['./quest-single-page.component.scss']
})
export class QuestSinglePageComponent implements OnInit, OnDestroy {

  quest?: QuestMetadata;
  creatingRequest = false;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private questsCrudService: QuestsCrudService,
    private globalSpinnerService: GlobalSpinnerService,
    private questPublishingService: QuestPublishingService,
    private cdRef: ChangeDetectorRef,
    private transloco: TranslocoService,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((value) => {
      this.globalSpinnerService.showSpinner();
      this.questsCrudService
        .loadQuestById(value['id'], true)
        .pipe(takeUntil(this.destroy$))
        .subscribe((quest) => {
          this.globalSpinnerService.hideSpinner();

          this.quest = quest;
          this.cdRef.detectChanges();
        });
    });
  }

  navigateToEditor(quest: QuestMetadata) {
    let url = '/quest-editor';

    if (quest.adaptive) {
      url += '/adaptive';
    }

    url += `/${quest.id}`;

    this.router.navigate([url]);
  }

  goBack() {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  createPublishRequest() {
    this.creatingRequest = true;
    this.questPublishingService.createPublishRequest(this.quest!.id)
      .pipe(
        finalize(() => this.creatingRequest = false),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.quest.publicationRequest = {
          id: value.id,
          status: value.status,
          questId: value.quest,
          createdAt: new Date(value.created_at),
          updatedAt: new Date(value.updated_at)
        };

        this.cdRef.detectChanges();
      });
  }

  isPublishRequestDisabled() {
    return this.creatingRequest || this.quest?.publicationRequest?.status === 'pending';
  }

  getClassForPublishRequestButton() {
    if (this.isPublishRequestDisabled()) {
      return 'primary-bar-btn';
    } else if (this.quest?.publicationRequest?.status === 'rejected') {
      return 'warning-bar-btn';
    } else {
      return 'primary-bar-btn';
    }
  }

  getTextForPublishRequestButton() {
    if (this.quest?.publicationRequest?.status === 'pending') {
      return this.transloco.translate('makePublicRequestPending');
    } else if (this.quest?.publicationRequest?.status === 'rejected') {
      return this.transloco.translate('requestRejected');
    } else {
      return this.transloco.translate('requestToMakePublic');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
