import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, take} from 'rxjs';
import {StepperStep} from './stepper.component';

@Injectable()
export class StepperService implements OnDestroy{

  // @ts-ignore
  currentStep$ = new BehaviorSubject<StepperStep>(null);
  moveByStep$ = new Subject<boolean>();
  private changeStep$ = new Subject<number>();

  constructor() { }

  onStepChange$(): Observable<StepperStep> {
    return this.currentStep$.asObservable();
  }

  manualSwitchStep(index: number): void {
    this.changeStep$.next(index);
  }

  goToNextStep(): void {
    this.moveByStep$.next(true);
  }

  goToPrevStep(): void {
    this.moveByStep$.next(false);
  }

  onManualSwitchStep(): Observable<number> {
    return this.changeStep$.asObservable();
  }

  completeCurrentStep(): void {
    this.currentStep$.pipe(take(1)).subscribe(value => {
      value.completed = true;
    })
  }

  //TODO I think we should be able to remove completion

  ngOnDestroy(): void {
    this.currentStep$.complete();
    this.changeStep$.complete();
    this.moveByStep$.complete();
  }
}
