import {Component, Input, OnInit} from '@angular/core';
import {QuestGameAccess, QuestMetadata} from 'quest-atlas-angular-components';
import {QuestStepObjectiveType} from 'quest-atlas-angular-components';
import {timeToPrettyText, toEUCalendarDateString} from 'quest-atlas-angular-components';
import {QuestAvailabilityTypes} from 'quest-atlas-angular-components';
import {capitalizeFirstLetter} from 'quest-atlas-angular-components';
import {TimeSpan} from 'quest-atlas-angular-components';
import {TranslocoService} from '@ngneat/transloco';
import {filter, take} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-quest-view',
  templateUrl: './quest-view.component.html',
  styleUrls: ['./quest-view.component.scss']
})
export class QuestViewComponent implements OnInit {

  readonly QuestStepObjectiveType = QuestStepObjectiveType;

  @Input() quest!: QuestMetadata;

  orgLogo: string | SafeHtml;

  questDescription: string[] = [];
  questTypeImgName!: string;
  prettyQuestTypeName!: string;

  privacyTypeImdName!: string;
  privacyTypeName!: string;

  availability?: string;

  timer?: string;

  questDifficulty!: string;

  approxDistance?: string;
  approxTime?: string;

  stepsInfo: Array<{
    objectiveType: string,
    timer?: string,
    stepDescription?: string[],
    finalDescription?: string[]
  }> = [];

  levelRequired?: QuestGameAccess;
  onlyOneCompletion?: boolean;

  constructor(private transloco: TranslocoService, private ds: DomSanitizer) {
  }

  ngOnInit(): void {
    this.init()
    this.transloco.events$.pipe(filter(event => event.type === 'translationLoadSuccess'), take(1)).subscribe(() => {
      this.init();
    });
  }

  private init(): void {
    this.availability = this.transloco.translate('always');

    if (this.quest.organization && this.quest.organization.secondaryLogo) {
      if (this.quest.organization.secondaryLogo.startsWith('http')) {
        this.orgLogo = this.quest.organization.secondaryLogo;
      } else {
        this.orgLogo = this.ds.bypassSecurityTrustHtml(this.quest.organization.secondaryLogo);
      }
    }

    this.questTypeImgName = this.quest.competitiveMode ? 'award' : (this.quest.teamMode ? 'users' : 'user');
    this.prettyQuestTypeName = this.quest.competitiveMode ?
      this.transloco.translate('competitive') : (this.quest.teamMode ? this.transloco.translate('team') : this.transloco.translate('single'));

    this.privacyTypeImdName = this.quest.info?.public ? 'planet' : 'lock';
    this.privacyTypeName = this.quest.info?.public ? this.transloco.translate('public') : this.transloco.translate('private');

    if (this.quest.description && this.quest.description.length > 0 && this.quest.description[0].type === 'text') {
      this.questDescription = this.quest.description[0].data.split('\n');
    }

    if (this.quest.config.timer) {
      this.timer = timeToPrettyText(this.quest.config.timer);
    }

    if (this.quest.info?.availability) {
      if (this.quest.info.availability.type === QuestAvailabilityTypes.fromDateToDate) {
        this.availability = `${toEUCalendarDateString(this.quest.info.availability.data.fromDate)} - ${toEUCalendarDateString(this.quest.info.availability.data.toDate)}`;
      } else if (this.quest.info.availability.type === QuestAvailabilityTypes.fromDate) {
        this.availability = `${this.transloco.translate('from')} ${toEUCalendarDateString(this.quest.info.availability.data.fromDate)}`;
      } else if (this.quest.info.availability.type === QuestAvailabilityTypes.toDate) {
        this.availability = `${this.transloco.translate('to')} ${toEUCalendarDateString(this.quest.info.availability.data.toDate)}`;
      }
    }

    this.questDifficulty = capitalizeFirstLetter(this.transloco.translate(this.quest.info!.difficulty.toString().toLowerCase()));

    if (this.quest.info?.approxDistance) {
      if (this.quest.info.approxDistance === 1) {
        this.approxDistance = '< 1' + this.transloco.translate('kilometerShort')
      } else if (this.quest.info.approxDistance === 7) {
        this.approxDistance = `7${this.transloco.translate('kilometerShort')}+`;
      } else {
        this.approxDistance = `${this.quest.info.approxDistance}${this.transloco.translate('kilometerShort')}`
      }
    }

    if (this.quest.info?.approxTime) {
      this.approxTime = timeToPrettyText(this.quest.info.approxTime);
      // if (this.quest.info.approxTime.hours <= 1) {
      //   this.approxTime = '< 1 ' + this.transloco.translate('dt.h')
      // } else {
      //   this.approxTime = `${this.quest.info.approxTime.hours}+ ` + this.transloco.translate('dt.h')
      // }
    }

    if (this.quest.gameAccess) {
      this.quest.gameAccess.forEach((access) => {
        if (access.accessType === 'level') {
          this.levelRequired = access;
        }

        if (access.accessType === 'onlyOneCompletion') {
          this.onlyOneCompletion = !!access.config?.['isSet'];
        }
      });
    }

    for (const step of this.quest.steps) {
      let timer = undefined;

      if (step.functionalMetadata.timer) {
        timer = timeToPrettyText(step.functionalMetadata.timer);
      }
      let stepDescription: string[] = ['-'];
      // form step description
      if (step.functionalMetadata.clueMetadata
        && step.functionalMetadata.clueMetadata.length > 0
        && step.functionalMetadata.clueMetadata[0].type === 'text'
        && step.functionalMetadata.clueMetadata[0].data) {
        stepDescription = step.functionalMetadata.clueMetadata[0].data.split('\n');
      }

      let finalDescription: string[] = ['-'];
      if (step.finalDescription) {
        finalDescription = step.finalDescription.split('\n');
      }

      this.stepsInfo.push({
        objectiveType: step.functionalMetadata.objectives[0].type === QuestStepObjectiveType.markerFind ?
          this.transloco.translate('hiddenPlace') : this.transloco.translate('place'),
        timer,
        stepDescription,
        finalDescription
      });
    }
  }

  approxTimeToPretty(time?: TimeSpan): string {
    if (!time || time.hours === 0) {
      return '-';
    }

    if (time.hours === 1) {
      return `< 1 ${this.transloco.translate('dt.h')}`;
    }

    return `${time.hours}+ ${this.transloco.translate('dt.h')}`;
  }

}
