<ul class="stepper-container">
  @for (step of steps; track step; let i = $index) {
    <li class="stepper-item" [ngClass]="{ completed: step.completed }">
      <div
        class="step-index"
      [ngClass]="{
        clickable: i !== steps.length - 1,
        active: (stepperService.currentStep$ | async) == step
      }"
        (click)="clickOnStep(i)"
        >
        @if (!step.completed) {
          <span>{{ i + 1 }}</span>
        }
        @if (step.completed) {
          <svg class="regular-img-size">
            <use xlink:href="assets/project-icons/check.svg#c"></use>
          </svg>
        }
      </div>
      <p>{{ step.label }}</p>
    </li>
  }
</ul>
