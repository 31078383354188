import {Component, Input, OnInit} from '@angular/core';
import {NgxPopperjsTriggers} from 'ngx-popperjs';
import {NgxPopperjsPlacements} from 'ngx-popperjs';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input() title?: string;
  @Input() paragraphs?: string[] = [];

  NgxPopperjsTriggers = NgxPopperjsTriggers;
  NgxPopperjsPlacements = NgxPopperjsPlacements;

  constructor() { }

  ngOnInit(): void {
  }

}
