<svg [popper]="pc"
  [popperTrigger]="NgxPopperjsTriggers.hover"
  [popperPlacement]="NgxPopperjsPlacements.RIGHT"
  [popperApplyClass]="'popperApplyClass'"
  >
  <use xlink:href="assets/project-icons/help.svg#c"></use>
</svg>

<popper-content #pc>
  @if (title) {
    <div class="general-big-text">{{title}}</div>
  }
  @for (par of paragraphs; track par) {
    <div class="general-text" style="min-width: 200px">{{par}}</div>
  }
</popper-content>

