import {ChangeDetectorRef, Component, OnDestroy, OnInit, signal} from '@angular/core';
import {QuestMetadata} from 'quest-atlas-angular-components';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {GlobalSpinnerService} from '../../services/global-spinner.service';
import {QuestPublishingService} from '../../services/quest-publishing.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-quest-admin-single-page',
  templateUrl: './quest-admin-single-page.component.html',
  styleUrls: ['./quest-admin-single-page.component.scss']
})
export class QuestAdminSinglePageComponent implements OnInit, OnDestroy {

  quest?: QuestMetadata;
  status = signal<string>('pending')

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private questsCrudService: QuestsCrudService,
    private globalSpinnerService: GlobalSpinnerService,
    private questPublishingService: QuestPublishingService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((value) => {
      this.globalSpinnerService.showSpinner();
      this.questsCrudService
        .loadQuestById(value['id'], true)
        .pipe(takeUntil(this.destroy$))
        .subscribe((quest) => {
          this.globalSpinnerService.hideSpinner();

          this.quest = quest;
          this.status.set(quest.publicationRequest?.status || 'pending')
          this.cdRef.detectChanges();
        });
    });
  }

  navigateToEditor(quest: QuestMetadata) {
    this.router.navigate([`/quest-editor/${quest.id}`]);
  }

  goBack() {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  rejectPublishRequest(): void {
    this.questPublishingService.rejectPublishRequest(this.quest!.publicationRequest!.id).subscribe(() => {
      this.status.set('rejected')
    });
  }

  approvePublishRequest(): void {
    this.questPublishingService.approvePublishRequest(this.quest!.publicationRequest!.id).subscribe(() => {
      this.status.set('approved')
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
