import {Component, input, model} from '@angular/core';
import {NgClass} from '@angular/common';
import {SvgWrapperComponent} from '../svg-wrapper/svg-wrapper.component';

@Component({
  selector: 'app-collapsable-section',
  standalone: true,
  imports: [
    SvgWrapperComponent,
    NgClass
  ],
  templateUrl: './collapsable-section.component.html',
  styleUrl: './collapsable-section.component.scss'
})
export class CollapsableSectionComponent {
  collapsed = model<boolean>(true);
  title = input.required<string>();
}
