import {Component, OnDestroy, OnInit} from '@angular/core';
import {QuestPublishRequestsService} from '../../services/admin/quest-publish-requests.service';
import {Subject} from 'rxjs';
import {QuestPublicationRequest} from 'quest-atlas-angular-components';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-quest-publish-requests-admin',
  templateUrl: './quest-publish-requests-admin.component.html',
  styleUrls: ['./quest-publish-requests-admin.component.scss']
})
export class QuestPublishRequestsAdminComponent implements OnInit, OnDestroy {

  publishRequests$ = this.questPublishRequestsService.getQuestPublishRequests$();

  private destroy$ = new Subject<void>();

  constructor(
    private questPublishRequestsService: QuestPublishRequestsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  navToQuest(request: QuestPublicationRequest) {
    this.router.navigate([`./single/${request.questId}`], {relativeTo: this.activatedRoute});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
