import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent {
  constructor(private router: Router) { }

  public navigateToHome(): void {
    this.router.navigate(['']);
  }
}
