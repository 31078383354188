import {GenericZoneEditEngine} from './generic-zone-edit-engine.class';
import {Observable} from 'rxjs';
import {MapControlPanelAction, MapEditEngineInstancesCluster} from './map-edit-engine.interface';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import {QGraph} from 'quest-atlas-angular-components';

export class HazardZoneEditEngine extends GenericZoneEditEngine {
  constructor(map: mapboxgl.Map,
              mapControlPanelAction$: Observable<MapControlPanelAction>,
              cluster: MapEditEngineInstancesCluster<HazardZoneEditEngine>,
              zone?: QGraph,
              callbackOnAnyClick?: (zoneEngine: GenericZoneEditEngine) => void) {
    super(map, mapControlPanelAction$,'hazard-zones', 'hazard-zones-outline', cluster, zone, callbackOnAnyClick);
  }

  getStrokeColor(): string {
    return '#FF3B30';
  }

}
