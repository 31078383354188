import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-active-chip',
  templateUrl: './active-chip.component.html',
  styleUrls: ['./active-chip.component.scss']
})
export class ActiveChipComponent implements OnInit {

  @Input() text!: string;
  @Input() color: 'default' | 'green' | 'red' | 'yellow' = 'default';
  @Input() selected?: boolean;

  @Output() close = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  getCssClasses(): string {
    let classes = this.selected ? 'selected' : '';
    if (this.color === 'default') {
      return classes;
    }

    return `${classes} color-${this.color} state-${this.color}`;
  }

  closeClick($event): void {
    this.close.emit();
    $event.stopPropagation();
  }
}
