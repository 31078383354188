import {TimeSpan} from '../interfaces/common.interfaces';

export const timerFromString = (timerStr: string): TimeSpan => {
  if (timerStr) {
    if (timerStr.charAt(2) === ':') {
      if (timerStr.charAt(5) === ':') {
        return {
          hours: Number(timerStr.charAt(0) + timerStr.charAt(1)),
          minutes: Number(timerStr.charAt(3) + timerStr.charAt(4)),
          seconds: Number(timerStr.charAt(6) + timerStr.charAt(7))
        }
      }

      return {
        hours: 0,
        minutes: Number(timerStr.charAt(0) + timerStr.charAt(1)),
        seconds: Number(timerStr.charAt(3) + timerStr.charAt(4))
      }
    }

    if (timerStr.length === 6) {
      return {
        hours: Number(timerStr.charAt(0) + timerStr.charAt(1)),
        minutes: Number(timerStr.charAt(2) + timerStr.charAt(3)),
        seconds: Number(timerStr.charAt(4) + timerStr.charAt(5))
      }
    } else if (timerStr.length === 4) {
      return {
        hours: 0,
        minutes: Number(timerStr.charAt(0) + timerStr.charAt(1)),
        seconds: Number(timerStr.charAt(2) + timerStr.charAt(3))
      }
    }
  }

  return {hours: 0, minutes: 0, seconds: 0};
}
