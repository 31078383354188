import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {asyncScheduler, BehaviorSubject, filter, finalize, observeOn, Subject, take, takeUntil} from 'rxjs';
import {
  isObeliskZoneCircle,
  Obelisk, ObeliskImage,
  ObeliskZoneCircle,
  ObeliskZonePolygon,
  RelativeCoords
} from 'quest-atlas-angular-components';
import {ObeliskType} from 'quest-atlas-angular-components';
import * as mapboxgl from 'mapbox-gl';
import {GeoCoords} from 'quest-atlas-angular-components';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {addLayersToMap, coordsToQGraph, drawMarkerOnMap} from 'quest-atlas-angular-components';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ChooserOption} from '../../common-components/chooser/chooser.component';
import * as turf from '@turf/turf';
import {metersToPretty} from 'quest-atlas-angular-components';
import {ObeliskZoneEditEngine} from './obelisk-zone-edit-engine.class';
import {QuestDropdownValues} from '../../common-components/dropdown/dropdown.component';
import {ExplorationService} from 'quest-atlas-angular-components';
import {ActivatedRoute, Router} from '@angular/router';
import {EXPLORATION_PATH} from '../../app-routing.module';
import {readImageFromClipboard} from '../../functions/file.functions';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-obelisks-editor',
  templateUrl: './obelisks-editor.component.html',
  styleUrls: ['./obelisks-editor.component.scss']
})
export class ObelisksEditorComponent implements OnInit, AfterContentInit, OnDestroy {
  readonly MAX_NUMBER_OF_IMAGES = 11;

  obeliskForm: FormGroup;

  obeliskMarker: mapboxgl.Marker;

  map!: mapboxgl.Map;
  mapLoaded = false;

  editingObeliskCenter = false;
  editingObeliskZone = false;

  userGeo?: GeoCoords;
  isNew = true;

  obelisk$ = new BehaviorSubject<Obelisk>(null);

  zoneTypeOptions: ChooserOption[] = [
    {name: 'Circle', value: 'circle', chosen: true},
    {name: 'Polygon', value: 'polygon'}
  ];

  importanceOptions: QuestDropdownValues[] = [
    {title: 'Small', value: 'small'},
    {title: 'Medium', value: 'medium'},
    {title: 'Big', value: 'big'},
    {title: 'National', value: 'national'},
    {title: 'World', value: 'world'},
  ]

  polygonZoneEditEngine: ObeliskZoneEditEngine;

  saving = false;

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private explorationService: ExplorationService,
              private router: Router) {
  }

  ngOnInit(): void {
    // TODO implement caching like in quest editor or chains editor
    navigator.geolocation.getCurrentPosition((value) => {
      this.userGeo = {lng: value.coords.longitude, lat: value.coords.latitude};
    });

    this.activatedRoute.params.pipe(observeOn(asyncScheduler)).subscribe((value) => {
      if (value['id'] === 'new') {
        this.isNew = true;
        this.obelisk$.next({
          name: '',
          type: ObeliskType.stationary,
          importance: 'medium',
          zone: {
            type: 'circle',
            radius: 10,
          }
        });
      } else {
        this.isNew = false;
        this.explorationService.getObeliskById$(value['id']).pipe(takeUntil(this.destroy$)).subscribe((obelisk) => {
          this.obelisk$.next(obelisk);
          this.obeliskMarker = this.drawObeliskMarker(obelisk.location);
          this.map.flyTo({center: obelisk.location});
        });
      }
    });

    this.obelisk$.pipe(filter(value => !!value), take(1), takeUntil(this.destroy$)).subscribe(obelisk => {
      this.obeliskForm = this.fb.group({
        name: [obelisk.name, [Validators.required]],
        subTitle: [obelisk.subTitle, []],
        description: [obelisk.description, []],
        svg: [obelisk.svg, [Validators.required]],
        type: [obelisk.type, [Validators.required]],
        category: [obelisk.category, []],
        importance: [this.importanceOptions.find(i => i.value === obelisk.importance), [Validators.required]],
        address: [obelisk.address, [Validators.required]],
        howToGet: [obelisk.config?.['howToGet'], []],
        website: [obelisk.config?.['website'], []],
        location: [obelisk.location, [Validators.required]],
        zoneType: [obelisk.zone.type, [Validators.required]],
        zoneRadius: [isObeliskZoneCircle(obelisk.zone) ? obelisk.zone.radius : 10, []],
        polygonVertices: [isObeliskZoneCircle(obelisk.zone) ? [] : obelisk.zone.vertices, []],
        images: [obelisk.images || [], []]
      });

      if (isObeliskZoneCircle(obelisk.zone)) {
        this.zoneTypeOptions[0].chosen = true;
        this.zoneTypeOptions[1].chosen = false;
        // just to give time for the map to load
        if (!this.isNew) {
          setTimeout(() => {
            this.onRadiusChange((obelisk.zone as ObeliskZoneCircle).radius);
          }, 1000);
        }
      } else {
        this.zoneTypeOptions[0].chosen = false;
        this.zoneTypeOptions[1].chosen = true;
      }

      this.obeliskForm.get('zoneRadius')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.onRadiusChange(value);
      });

      this.polygonZoneEditEngine = new ObeliskZoneEditEngine(this.map, isObeliskZoneCircle(obelisk.zone) ? null : obelisk.zone.absolutePolygon, () => {
      });
      this.polygonZoneEditEngine.onZoneConfirmed$().subscribe((zone) => {
        const polygonZone: RelativeCoords[] = zone.connections.map((connection) => {
          const v = zone.vertices[connection.startVertexId];

          return {
            lngDiff: this.obeliskForm.value.location.lng - v.coords.lng,
            latDiff: this.obeliskForm.value.location.lat - v.coords.lat
          }
        });

        this.obeliskForm.patchValue({
          polygonVertices: polygonZone
        });
      });
    });

    // @ts-ignore
    mapboxgl.accessToken = environment.mapboxPublicToke;
    let centerAndZoom = {
      zoom: 2,
      center: [0, 0]
    };

    const lastLocation = this.getLastLocation();

    if (lastLocation) {
      centerAndZoom = {
        zoom: 14,
        center: [lastLocation.lng, lastLocation.lat]
      };
    } else if (this.userGeo) {
      centerAndZoom = {
        zoom: 13,
        center: [this.userGeo.lng, this.userGeo.lat]
      };
    }

    // @ts-ignore
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/outdoors-v11',
      ...centerAndZoom
    });

    // Add map controls
    this.map.addControl(
      new MapboxGeocoder({
        accessToken: environment.mapboxPublicToke,
        mapboxgl: mapboxgl
      })
    );

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      this.mapLoaded = true;

      addLayersToMap(this.map);
    });

    this.map.on('click', (ev: any) => {
      if (this.editingObeliskCenter) {
        this.storeLastLocation(ev.lngLat);

        if (this.obeliskMarker) {
          this.removeObeliskMarker();
        }

        this.obeliskMarker = this.drawObeliskMarker(ev.lngLat);

        if (this.obeliskForm.value.zoneType === 'circle') {
          this.changeRadius(this.obeliskForm.value.zoneRadius);
        } else if (this.obeliskForm.value.zoneType === 'polygon') {
          this.polygonZoneEditEngine.setZone(coordsToQGraph(
            this.obeliskForm.value.polygonVertices.map((v: RelativeCoords) => {
              return {
                lng: ev.lngLat.lng - v.lngDiff,
                lat: ev.lngLat.lat - v.latDiff
              }
            })
          ));
        }

        this.editingObeliskCenter = false;
      } else if (this.obeliskForm.value.zoneType === 'polygon') {
        this.addPolygonVertex(ev.lngLat);
      }
    });
  }

  ngAfterContentInit(): void {
    const addPhotoInput = document.getElementById('add-photo-input');
    addPhotoInput!.addEventListener('change', (event: any) => {
      const files = event?.target?.files;
      if (!files || files.length === 0) {
        console.error('No files uploaded');
        return;
      }

      this.readImage(files[0]);
    });
  }

  createObelisk(): void {
    this.saving = true;

    const obelisk: Obelisk = this.obeliskFromCurrentForm();

    this.explorationService.createObelisk$(obelisk)
      .pipe(
        finalize(() => this.saving = false)
      ).subscribe(() => {
        this.router.navigate([EXPLORATION_PATH]);
    });
  }

  updateObelisk(): void {
    this.obelisk$.pipe(take(1)).subscribe(value => {
      const obelisk: Obelisk = this.obeliskFromCurrentForm();
      obelisk.uuid = value.uuid;

      this.explorationService.updateObelisk$(obelisk).subscribe(() => {
        this.saving = false;
        this.router.navigate([EXPLORATION_PATH]);
      });
    });
  }

  isValid(): boolean {
    return this.obeliskForm?.valid;
  }

  clickObeliskBtn(): void {
    this.editingObeliskZone = false;
    this.editingObeliskCenter = true;
  }

  choseZoneType(option: ChooserOption): void {
    if (option.value === this.obeliskForm.value.zoneType) {
      return;
    }

    this.obeliskForm.patchValue({
      zoneType: option.value
    });

    this.clearZone();

    if (option.value === 'circle') {
      this.polygonZoneEditEngine.stopEditing();
      this.changeRadius(this.obeliskForm.value.zoneRadius);
    } else if (option.value === 'polygon') {
      this.polygonZoneEditEngine.reveal();
      this.polygonZoneEditEngine.startEditing();
    }
  }

  removeObeliskMarker(): void {
    this.obeliskMarker.remove();
    this.obeliskMarker = null;
    this.obeliskForm.patchValue({
      location: null
    });
  }

  private obeliskFromCurrentForm(): Obelisk {
    const obelisk: Obelisk = {
      name: this.obeliskForm.value.name,
      subTitle: this.obeliskForm.value.subTitle,
      description: this.obeliskForm.value.description,
      svg: this.obeliskForm.value.svg,
      type: this.obeliskForm.value.type,
      category: this.obeliskForm.value.category,
      importance: this.obeliskForm.value.importance.value,
      address: this.obeliskForm.value.address,
      location: this.obeliskForm.value.location,
      images: this.obeliskForm.value.images,
      config: {
        howToGet: this.obeliskForm.value.howToGet,
        website: this.obeliskForm.value.website
      }
    }

    if (this.obeliskForm.value.zoneType === 'circle') {
      obelisk.zone = {
        type: 'circle',
        radius: this.obeliskForm.value.zoneRadius
      }
    } else if (this.obeliskForm.value.zoneType === 'polygon') {
      obelisk.zone = {
        type: 'polygon',
        vertices: this.obeliskForm.value.polygonVertices
      }
    }

    return obelisk;
  }

  addImageClick(): void {
    if (this.getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    const addPhotoInput = document.getElementById('add-photo-input');
    addPhotoInput?.click();
  }

  clickPasteImage(): void {
    if (this.getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    readImageFromClipboard()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.readImage(value);
        }
      });
  }

  getCurrentImages(): any[] {
    return this.obeliskForm.get('images')!.value;
  }

  removeImgByUuid(uuid: string): void {
    const images = this.getCurrentImages();
    let imagesNew = images.filter((i: ObeliskImage) => i.uuid !== uuid);

    this.obeliskForm.get('images')?.setValue(imagesNew);
  }

  private readImage(file: any): void {
    const reader = new FileReader();
    const existingImages = this.obeliskForm.get('images')?.value || [];
    reader.addEventListener('load', () => {
      this.obeliskForm.get('images')?.setValue([...existingImages, {
        uuid: uuidv4(),
        order: existingImages.length,
        uri: reader.result
      } as ObeliskImage]);

      reader.removeAllListeners('load');
    });

    reader.readAsDataURL(file);
  }

  private drawObeliskMarker(coords: GeoCoords): mapboxgl.Marker {
    this.obeliskForm.patchValue({
      location: coords
    });

    return drawMarkerOnMap(coords, this.map, '../../../../assets/map-icons/active-marker-1.svg');
  }

  private changeRadius(value: number): void {
    this.obeliskForm.patchValue({
      zoneRadius: value
    });

    this.onRadiusChange(value);
  }

  private onRadiusChange(value: number): void {
    const center = this.obeliskForm.value.location;

    const circle = turf.circle([center.lng, center.lat], value, {steps: 60, units: 'meters'});

    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(circle);
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(circle);
  }

  private addPolygonVertex(coords: GeoCoords): void {
    this.polygonZoneEditEngine.clickOnMap(coords);
  }

  private clearZone(): void {
    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
    this.polygonZoneEditEngine.hide();
  }

  // TODO move somewhere to a common place
  goToMyLocation(): void {
    if (this.userGeo) {
      this.map.setCenter([this.userGeo.lng, this.userGeo.lat]);
    } else {
      navigator.geolocation.getCurrentPosition((value) => {
        this.userGeo = {lng: value.coords.longitude, lat: value.coords.latitude};
        this.map.setCenter([this.userGeo.lng, this.userGeo.lat]);
      });
    }
  }

  // TODO move somewhere to a common place
  toggleMagStyle(): void {
    if (this.map.getStyle().name === 'Mapbox Outdoors') {
      this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
    } else {
      this.map.setStyle('mapbox://styles/mapbox/outdoors-v11');
    }

    this.map.once('styledata', () => {
      addLayersToMap(this.map);

      setTimeout(() => {
        this.map.triggerRepaint();
        this.polygonZoneEditEngine.externalRedraw();
      }, 100);
    });
  }

  private storeLastLocation(coords: GeoCoords): void {
    localStorage.setItem('lastLocation', JSON.stringify(coords));
  }

  private getLastLocation(): GeoCoords | null {
    const ll = localStorage.getItem('lastLocation');
    return ll ? JSON.parse(ll) : null;
  }

  ngOnDestroy(): void {
    this.polygonZoneEditEngine.destroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly metersToPretty = metersToPretty;
}
