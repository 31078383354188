import {AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StepperService} from '../../common-components/stepper/stepper.service';
import {
  asyncScheduler,
  catchError,
  filter,
  Observable,
  observeOn,
  Subject,
  take,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import {StepperStep} from '../../common-components/stepper/stepper.component';
import {FormComponent, GeneralFormData, OptionsFormData, StepsFormData} from './form-component.interface';
import {ModalButton} from '../../common-components/modal/modal.component';
import {MapConfigData} from './map-editor/map-editor.component';
import {
  QuestStepObjectiveType,
  User,
  QuestsCrudService,
  isStartZoneCircle,
  QuestGameAccess,
  QuestMetadata,
  QuestAvailabilityTypes,
  QuestMode
} from 'quest-atlas-angular-components';
import {CalendarDate, Dict} from 'quest-atlas-angular-components';
import {GeoCoords} from 'quest-atlas-angular-components';
import {ImgInfoBody} from 'quest-atlas-angular-components';
import {covertToQuestDropdownValue} from '../../common-components/dropdown/dropdown.component';
import {isTypeMarkerFind, isTypeMarkerReach, isTypeAdaptive} from 'quest-atlas-angular-components';
import {
  getDifficultyOptions,
  getObjectiveTypeOptions,
  getPrivacyOptions,
  getQuestTypeOptions, getRecommendedForOptions
} from './dropdown-options-for-forms';
import {GlobalSpinnerService} from '../../services/global-spinner.service';
import {lengthToDegrees} from '@turf/helpers';
import {MAP_EDITOR_URL_FRAGMENT} from '../../constants/nav.constants';
import {TranslocoService} from '@ngneat/transloco';
import {IDB_NAME, IDB_VERSION} from './quest-editor.constants';
import {UserService} from '../../services/user.service';
import {DomSanitizer} from '@angular/platform-browser';
import {idbGeneralFormStore, idbOptionsFormStore, idbStepsFormStore} from './quest-editor.functions';

@Component({
  selector: 'app-quest-editor',
  templateUrl: './quest-editor.component.html',
  styleUrls: ['./quest-editor.component.scss'],
  providers: [StepperService]
})
export class QuestEditorComponent implements OnInit, AfterContentInit, OnDestroy {
  title = this.transloco.translate('editQuest');
  isNew = false;
  originalQuest?: QuestMetadata;
  questId: number;
  adaptive = false;

  steps = [
    {name: 'General', label: this.transloco.translate('qeSteps.general')},
    {name: 'Steps', label: this.transloco.translate('qeSteps.steps')},
    {name: 'Options', label: this.transloco.translate('qeSteps.options')},
    {name: 'Publishing', label: this.transloco.translate('qeSteps.publishing')}
  ];

  generalFormValues: GeneralFormData = {};
  optionsFormValues: OptionsFormData = {};
  stepsFormValues: StepsFormData = {};

  showCloseConfirmation = false;
  showMapEditor = false;
  mapEditorConfig?: MapConfigData;
  mapEditorOptions = {
    mapForStepIndex: 0,
    objectiveType: QuestStepObjectiveType.markerFind
  };

  modalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('stay'),
      onClick: () => (this.showCloseConfirmation = false)
    },
    {
      type: 'error',
      text: this.transloco.translate('leave'),
      onClick: () => this.close()
    }
  ];

  userGeo?: GeoCoords;
  questToSave: QuestMetadata;

  currentStep$: Observable<StepperStep> = this.stepperService.onStepChange$();

  private _generalFormStep!: FormComponent;
  private _optionsFormStep!: FormComponent;
  private _stepsFormStep!: FormComponent;
  private _publishingView: any;
  private db: IDBDatabase;

  currentStep?: FormComponent;
  user: User;

  get generalFormStep(): FormComponent {
    return this._generalFormStep;
  }

  @ViewChild('generalForm')
  set generalFormStep(value: FormComponent) {
    this._generalFormStep = value;

    if (value) {
      this.currentStep = value;
      this.cdr.detectChanges();
    }
  }

  get optionsFormStep(): FormComponent {
    return this._optionsFormStep;
  }

  @ViewChild('optionsForm')
  set optionsFormStep(value: FormComponent) {
    this._optionsFormStep = value;
    if (value) {
      this.currentStep = value;
      this.cdr.detectChanges();
    }
  }

  get stepsFormStep(): FormComponent {
    return this._stepsFormStep;
  }

  @ViewChild('stepsForm')
  set stepsFormStep(value: FormComponent) {
    this._stepsFormStep = value;
    if (value) {
      this.currentStep = value;
      this.cdr.detectChanges();
    }
  }

  get publishingView(): any {
    return this._publishingView;
  }

  @ViewChild('publishingView')
  set publishingView(value: any) {
    if (!value) {
      return;
    }
    this._publishingView = value;
    this.currentStep = undefined;
    this.cdr.detectChanges();
  }

  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stepperService: StepperService,
    private cdr: ChangeDetectorRef,
    private questsCrudService: QuestsCrudService,
    private globalSpinnerService: GlobalSpinnerService,
    private userService: UserService,
    private transloco: TranslocoService,
    private ds: DomSanitizer
  ) {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.adaptive = !!data['adaptive'];
    });
  }

  ngOnInit(): void {
    // TODO якщо хтось поміняв урлі з адаптів на просту (чи навпаки), то видати помилку, бо ми на квесті з беку будемо бачити, чи він адаптивний, чи ні

    this.userService.getUserInfo$().pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    const request = window.indexedDB.open(IDB_NAME, IDB_VERSION);
    request.onsuccess = (event) => {
      this.db = (event.target as any).result;
    };

    request.onupgradeneeded = (event) => {
      this.db = (event.target as any).result;

      if (!this.db.objectStoreNames.contains('quest.generalForm')) {
        this.db.createObjectStore('quest.generalForm', {autoIncrement: true});
      }
      if (!this.db.objectStoreNames.contains('quest.stepsForm')) {
        this.db.createObjectStore('quest.stepsForm', {autoIncrement: true});
      }
      if (!this.db.objectStoreNames.contains('quest.optionsForm')) {
        this.db.createObjectStore('quest.optionsForm', {autoIncrement: true});
      }
      if (!this.db.objectStoreNames.contains('quest.adaptive.generalForm')) {
        this.db.createObjectStore('quest.adaptive.generalForm', {autoIncrement: true});
      }
      if (!this.db.objectStoreNames.contains('quest.adaptive.stepsForm')) {
        this.db.createObjectStore('quest.adaptive.stepsForm', {autoIncrement: true});
      }
      if (!this.db.objectStoreNames.contains('quest.adaptive.optionsForm')) {
        this.db.createObjectStore('quest.adaptive.optionsForm', {autoIncrement: true});
      }
    };

    navigator.geolocation.getCurrentPosition((value) => {
      this.userGeo = {lng: value.coords.longitude, lat: value.coords.latitude};
    });

    this.activatedRoute.params.subscribe((value) => {
      if (value['id'] === 'new') {
        this.title = this.transloco.translate('newQuest');
        this.isNew = true;
      } else {
        this.globalSpinnerService.showSpinner();
        this.questsCrudService
          .loadQuestById(value['id'], true)
          .pipe(takeUntil(this.destroy$))
          .subscribe((quest) => {
            this.globalSpinnerService.hideSpinner();

            this.questId = quest.id;
            this.originalQuest = JSON.parse(JSON.stringify(quest));

            this.generalFormValues = {
              name: quest.name,
              mode: covertToQuestDropdownValue(
                quest.competitiveMode ? QuestMode.competitive : quest.teamMode ? QuestMode.group : QuestMode.single,
                getQuestTypeOptions()
              ),
              recommendedFor: covertToQuestDropdownValue(quest.info.recommendedFor, getRecommendedForOptions()),
              privacy: covertToQuestDropdownValue(quest.info.public, getPrivacyOptions())
            };

            if (quest.organization) {
              this.generalFormValues.organizationId = {
                value: quest.organization.id,
                title: quest.organization.name
              }

              if (quest.organization.secondaryLogo.startsWith('http')) {
                this.generalFormValues.organizationId['imgUrl'] = quest.organization.secondaryLogo;
              } else {
                this.generalFormValues.organizationId['imgSvg'] = this.ds.bypassSecurityTrustHtml(quest.organization.secondaryLogo);
              }
            }

            let levelRequired = 1;
            let onlyOneCompletion = false;

            if (quest.gameAccess) {
              quest.gameAccess.forEach((access) => {
                if (access.accessType === 'level') {
                  levelRequired = access.config['level'];
                }
              });

              onlyOneCompletion = !!quest.gameAccess.find(
                (access) => access.accessType === 'onlyOneCompletion'
              )?.config?.['isSet'];
            }

            this.optionsFormValues = {
              difficulty: covertToQuestDropdownValue(
                quest.info.difficulty,
                getDifficultyOptions()
              ),
              uploadedImages: quest.info.images,
              levelRequired,
              onlyOneCompletion,
              stepsHidden: quest.config.stepsHidden,
              tags: quest.info.tags,
              fromDate: quest.info.availability?.data?.fromDate ? new Date(quest.info.availability?.data?.fromDate.year, quest.info.availability?.data?.fromDate.month, quest.info.availability?.data?.fromDate.day) : undefined,
              toDate: quest.info.availability?.data?.toDate ? new Date(quest.info.availability?.data?.toDate.year, quest.info.availability?.data?.toDate.month, quest.info.availability?.data?.toDate.day) : undefined,
              approxDistance: quest.info.approxDistance,
              approxTime: quest.info.approxTime,
              description: quest.description?.at(0)?.data,
              address: quest.info.address
            };

            this.stepsFormValues = {
              steps: quest.steps.map((qs) => {
                let objectivesData: Dict<any> = {};

                const objective = qs.functionalMetadata.objectives?.at(0);

                if (isTypeMarkerReach(objective)) {
                  objectivesData['markerReach'] = objective.marker;
                } else if (isTypeMarkerFind(objective)) {
                  objectivesData['markerFind'] = objective.marker;
                } else if (isTypeAdaptive(objective)) {
                  objectivesData = objective;
                }

                return {
                  id: qs.id,
                  name: qs.name,
                  objectiveType: covertToQuestDropdownValue(qs.functionalMetadata.objectives[0].type, getObjectiveTypeOptions()),
                  task: qs.functionalMetadata.clueMetadata?.at(0)?.data,
                  finalDescription: qs.finalDescription,
                  objectivesData,
                  timer: qs.functionalMetadata.timer,
                  hazardZones: qs.functionalMetadata.hazardZones,
                  startZone: qs.functionalMetadata.startZone,
                  taskImages: qs.functionalMetadata.images,
                  canBeSkipped: qs.functionalMetadata.canBeSkipped,
                  encounter: qs.functionalMetadata.encounter,
                  precondition: qs.functionalMetadata.precondition
                };
              })
            };
          });
      }
    });

    this.activatedRoute.fragment.pipe(filter(v => v !== MAP_EDITOR_URL_FRAGMENT), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.showMapEditor = false;
      });

    // we need to wait for the last form updates to be applied
    this.currentStep$.pipe(observeOn(asyncScheduler, 50), takeUntil(this.destroy$)).subscribe((value) => {
      if (value?.name.toLowerCase() === 'publishing') {
        this.questToSave = this.formsToQuestMetadataBody();
      }
    });
  }

  ngAfterContentInit(): void {
  }

  clickNext() {
    this.stepperService.currentStep$.pipe(take(1)).subscribe((value) => {
      if (!value.completed) {
        this.stepperService.completeCurrentStep();
      }

      this.stepperService.goToNextStep();

      const formValue = this.currentStep?.submitForm();

      this.updateForms(formValue);
    });
  }

  clickBack() {
    if (this.currentStep) {
      this.updateForms(this.currentStep.getFormData());
    }

    this.stepperService.goToPrevStep();
  }

  askToClose(): void {
    this.showCloseConfirmation = true;
  }

  close(): void {
    this.router.navigate(['/home']);
  }

  saveQuest(draft = false): void {
    let quest = this.formsToQuestMetadataBody();
    quest.isDraft = draft;
    quest.adaptive = this.adaptive;

    if (!this.isNew) {
      quest.id = this.questId;
    }

    const firstStartZone = quest.steps[0].functionalMetadata.startZone;

    if (firstStartZone) {
      if (isStartZoneCircle(firstStartZone)) {
        quest.locationPoint = firstStartZone.coords;
      } else {
        quest.locationPoint = firstStartZone.vertices[firstStartZone.connections[0].startVertexId].coords;
      }
    } else {
      const firstStep = quest.steps[0];
      if (isTypeMarkerReach(firstStep.functionalMetadata.objectives[0])) {
        quest.locationPoint = firstStep.functionalMetadata.objectives[0].marker.coords;
      } else if (isTypeMarkerFind(firstStep.functionalMetadata.objectives[0])) {
        //we can't just put as a quest location the location of a marker to find. So, we have to randomise the location a bit
        const coords: GeoCoords = {...firstStep.functionalMetadata.objectives[0].marker.data.circleCenter};
        const a = lengthToDegrees(firstStep.functionalMetadata.objectives[0].marker.data.radius, 'meters');
        //500 is for "not closer than 500m" basically
        const b = lengthToDegrees(firstStep.functionalMetadata.objectives[0].marker.data.radius / 3, 'meters');

        const lngDelta = (Math.random() - 0.5) * 2 * a;
        const latDelta = (Math.random() - 0.5) * 2 * a;
        coords.lng += lngDelta + (lngDelta > 0 ? b : -b);
        coords.lat += latDelta + (latDelta > 0 ? b : -b);
        quest.locationPoint = coords;
      }
    }

    const obs$ = this.isNew ?
      this.questsCrudService.createQuest(quest).pipe(tap(value => {
        // we can't just replace the quest, because we'll lose images then
        quest.id = value.id;
        for (let i = 0; i < quest.steps.length; i++) {
          quest.steps[i].id = value.steps[i].id;
        }
      }))
      : this.questsCrudService.updateQuest(quest);

    this.globalSpinnerService.showSpinner();
    obs$.pipe(
      catchError((err) => {
        this.globalSpinnerService.hideSpinner();
        console.error(err);

        return throwError(() => err);
      }),
    ).subscribe(() => {
      this.globalSpinnerService.hideSpinner();

      this.updateImages(quest);

      if (this.db) {
        let transaction = this.db.transaction([
          idbGeneralFormStore(this.adaptive),
          idbOptionsFormStore(this.adaptive),
          idbStepsFormStore(this.adaptive)
        ], 'readwrite');
        transaction.objectStore(idbGeneralFormStore(this.adaptive)).clear();
        transaction.objectStore(idbOptionsFormStore(this.adaptive)).clear();
        transaction.objectStore(idbStepsFormStore(this.adaptive)).clear();
      }

      this.router.navigate(['/']);
    });
  }

  // TODO later may be optimised
  private updateImages(quest: QuestMetadata): void {
    const questImages = quest.info?.images || [];
    const stepsImages: (ImgInfoBody[] | undefined)[] = [];
    quest.info!.images = [];

    for (const step of quest.steps) {
      stepsImages.push(step.functionalMetadata.images || []);
      step.functionalMetadata.images = undefined;
    }

    //No need for takeUntil, because even if the component is destroyed, we want to save images after a quest is saved
    //saving only images never saved before (without url)
    let questImagesToSave = questImages.filter((img) => !img.url);
    let questImagesToDelete = [];

    if (this.originalQuest) {
      for (const prevImg of this.originalQuest.info.images) {
        let removed = true;
        // iterating to see if the image was removed
        for (const img of questImages) {
          if (img.name === prevImg.name) {
            removed = false;
          }
        }

        if (removed) {
          questImagesToDelete.push(prevImg);
        }
      }
    }

    if (questImagesToSave && questImagesToSave.length) {
      this.questsCrudService
        .saveImages(
          quest.id,
          questImagesToSave.map((img) => img.raw!),
          'quest',
          questImages.filter((img) => !!img.url).length)
        .subscribe();
    }

    for (const image of questImagesToDelete) {
      this.questsCrudService.deleteImage(image.name).subscribe();
    }

    for (let i = 0; i < stepsImages.length; i++) {
      const stepImages = stepsImages[i].filter((img) => !img.url);
      const stepImagesToDelete = [];

      if (this.originalQuest) {
        if (this.originalQuest.steps.length > i) {
          for (const prevImg of this.originalQuest.steps[i].functionalMetadata.images) {
            let removed = true;
            // iterating to see if the image was removed
            for (const img of stepsImages[i]) {
              if (img.name === prevImg.name) {
                removed = false;
              }
            }

            if (removed) {
              stepImagesToDelete.push(prevImg);
            }
          }
        }
      }

      if (stepImages && stepImages.length) {
        this.questsCrudService
          .saveImages(
            quest.steps[i].id,
            stepImages.map((img) => img.raw),
            'step',
            stepsImages[i].filter(img => !!img.url).length
          )
          .subscribe();
      }

      for (const image of stepImagesToDelete) {
        this.questsCrudService.deleteImage(image.name).subscribe();
      }
    }
  }

  formsToQuestMetadataBody(): QuestMetadata {
    const quest: QuestMetadata = {
      name: this.generalFormValues.name,
      organization: this.generalFormValues.organizationId && this.user
        ? this.user.organizations.filter((org) => org.id === this.generalFormValues.organizationId.value)[0]
        : undefined,
      locationPoint: undefined,
      steps: [],
      config: {
        // to make sure that the value is boolean
        stepsHidden: !!this.optionsFormValues.stepsHidden,
      },
      info: {
        images: this.optionsFormValues.uploadedImages,
        difficulty: this.optionsFormValues?.difficulty?.value,
        public: this.generalFormValues?.privacy?.value,
        recommendedFor: this.generalFormValues?.recommendedFor?.value,
        approxDistance: this.optionsFormValues.approxDistance,
        approxTime: this.optionsFormValues.approxTime,
        address: this.optionsFormValues.address,
        availability: {type: QuestAvailabilityTypes.always},
      },
      gameAccess: []
    };
    if (this.optionsFormValues.description) {
      quest.description = [{type: 'text', data: this.optionsFormValues.description}];
    }
    switch (this.generalFormValues.mode?.value) {
      case QuestMode.single:
        quest.teamMode = false;
        quest.competitiveMode = false;
        break;
      case QuestMode.group:
        quest.teamMode = true;
        quest.competitiveMode = false;
        break;
      case QuestMode.competitive:
        quest.teamMode = true;
        quest.competitiveMode = false;
        break;
    }

    if (this.optionsFormValues.levelRequired) {
      const originalLevel = this.originalQuest?.gameAccess?.find(access => access.accessType === 'level');

      const newLevelAccess: QuestGameAccess = {
        accessType: 'level',
        config: {level: this.optionsFormValues.levelRequired}
      };

      if (originalLevel) {
        newLevelAccess.id = originalLevel.id;
      }

      quest.gameAccess.push(newLevelAccess);
    }

    if (this.optionsFormValues.onlyOneCompletion) {
      const originalOnlyOneCompletion = this.originalQuest?.gameAccess?.find(access => access.accessType === 'onlyOneCompletion');

      const newOnlyOneCompletionAccess: QuestGameAccess = {accessType: 'onlyOneCompletion', config: {isSet: true}};

      if (originalOnlyOneCompletion) {
        newOnlyOneCompletionAccess.id = originalOnlyOneCompletion.id;
      }

      quest.gameAccess.push(newOnlyOneCompletionAccess);
    }

    if (this.optionsFormValues.fromDate && this.optionsFormValues.toDate) {
      quest.info!.availability = {
        type: QuestAvailabilityTypes.fromDateToDate,
        data: {
          fromDate: this.convertToCalendarDate(this.optionsFormValues.fromDate),
          toDate: this.convertToCalendarDate(this.optionsFormValues.toDate)
        }
      };
    } else if (this.optionsFormValues.fromDate) {
      quest.info!.availability = {
        type: QuestAvailabilityTypes.fromDate,
        data: {
          fromDate: this.convertToCalendarDate(this.optionsFormValues.fromDate)
        }
      };
    } else if (this.optionsFormValues.toDate) {
      quest.info!.availability = {
        type: QuestAvailabilityTypes.toDate,
        data: {
          toDate: this.convertToCalendarDate(this.optionsFormValues.toDate)
        }
      };
    }

    if (this.optionsFormValues.tags) {
      // doing this to omit sending selected property
      quest.info!.tags = this.optionsFormValues.tags.map((tag) => ({
        id: tag.id,
        name: tag.name,
        description: tag.description
      }));
    }

    for (const step of this.stepsFormValues.steps) {
      let objMetadata = {};

      if (step.objectiveType.value === QuestStepObjectiveType.markerReach) {
        objMetadata['marker'] = step.objectivesData.markerReach;
      } else if (step.objectiveType.value === QuestStepObjectiveType.markerFind) {
        objMetadata['marker'] = step.objectivesData.markerFind;
      } else if (isTypeAdaptive(step.objectivesData)) {
        objMetadata = step.objectivesData;
      }

      quest.steps.push({
        id: step.id,
        name: step.name,
        functionalMetadata: {
          timer: step.timer,
          clueMetadata: [
            {
              type: 'text',
              data: step.task
            }
          ],
          objectives: [
            {
              type: step.objectiveType.value,
              ...objMetadata
            }
          ],
          startZone: step.startZone,
          hazardZones: step.hazardZones,
          images: step.taskImages,
          canBeSkipped: step.canBeSkipped,
          encounter: step.encounter,
          precondition: step.precondition
        },
        finalDescription: step.finalDescription
      });
    }

    return quest;
  }

  openMapEditorForStep(event: any): void {
    this.updateForms(event.form);
    this.showMapEditor = true;

    this.router.navigate([`./`], {relativeTo: this.activatedRoute, fragment: MAP_EDITOR_URL_FRAGMENT});

    const step = event.form.steps[event.stepIndex];

    this.mapEditorOptions.mapForStepIndex = event.stepIndex;
    this.mapEditorOptions.objectiveType = step.objectiveType.value;

    this.mapEditorConfig = {
      hazardZones: step.hazardZones,
      startZone: step.startZone,
      markerReach: step.objectivesData?.markerReach,
      markerFind: step.objectivesData?.markerFind,
      timer: step.timer,
      startZoneHidden: event.stepIndex > 0
    };
  }

  onMapApply(event: MapConfigData) {
    this.showMapEditor = false;
    this.router.navigate([`./`], {
      relativeTo: this.activatedRoute,
      fragment: this.stepperService.currentStep$.value.name
    });

    const stepValues = this.stepsFormValues.steps[this.mapEditorOptions.mapForStepIndex];
    stepValues.hazardZones = event.hazardZones;
    stepValues.startZone = event.startZone;

    if (!stepValues.objectivesData) {
      stepValues.objectivesData = {};
    }

    if (event.markerFind) {
      stepValues.objectivesData.markerFind = event.markerFind;
    } else if (event.markerReach) {
      stepValues.objectivesData.markerReach = event.markerReach;
    }

    if (event.timer) {
      stepValues.timer = event.timer;
    }
  }

  private updateForms(formValue: any): void {
    switch (this.currentStep?.type) {
      case 'general':
        this.generalFormValues = formValue;
        break;
      case 'options':
        this.optionsFormValues = formValue;
        break;
      case 'steps':
        this.stepsFormValues = formValue;
    }
  }

  private convertToCalendarDate(d: any): CalendarDate {
    if (!(d instanceof Date)) {
      d = new Date(d);
    }

    return {day: d.getDate(), month: d.getMonth(), year: d.getFullYear()};
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
