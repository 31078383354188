<!--this is to trigger translation load, because it's lazy loaded-->
<ng-container *transloco="let t">
  <router-outlet></router-outlet>

  @if (globalSpinnerService.isShowSpinner()) {
    <div class="global-spinner">
      <mat-spinner></mat-spinner>
    </div>
  }
</ng-container>
