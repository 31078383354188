import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {QuestMetadata} from 'quest-atlas-angular-components';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortModule, Sort} from '@angular/material/sort';
import {BehaviorSubject, Observable, Subject, take, takeUntil, tap} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {compare} from 'quest-atlas-angular-components';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { NgClass } from '@angular/common';
import {TranslocoDirective, TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-quests-table-list',
  templateUrl: './quests-table-list.component.html',
  styleUrls: ['./quests-table-list.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    NgClass,
    TranslocoDirective
],
  standalone: true
})
export class QuestsTableListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() quests$: Observable<QuestMetadata[]>;
  @Input() showMenu = false;
  sortQuests$$ = new BehaviorSubject([]);

  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['name', 'address', 'updatedAt', 'createdAt', 'status', 'type'];
  dataSource!: MatTableDataSource<QuestMetadata>;

  @Output() select = new EventEmitter<QuestMetadata>();
  @Output() edit = new EventEmitter<QuestMetadata>();
  @Output() shareCollaborator = new EventEmitter<QuestMetadata>();
  @Output() sharePlayer = new EventEmitter<QuestMetadata>();
  @Output() delete = new EventEmitter<QuestMetadata>();

  destroy$ = new Subject<void>();

  constructor(private transloco: TranslocoService) { }

  ngOnInit(): void {
    if (this.showMenu) {
      this.displayedColumns.push('menu');
    }

    this.quests$
      .pipe(
        tap((quests) => this.sortQuests$$.next(quests.slice())),
        switchMap(() => this.sortQuests$$),
        takeUntil(this.destroy$)
      )
      .subscribe((sortQuests) => {
        this.dataSource = new MatTableDataSource<QuestMetadata>(sortQuests);
      });
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  sortData(sort: Sort) {
    this.quests$.pipe(take(1)).subscribe((quests) => {
      let sortedQuests = quests.slice();
      if (!sort.active || sort.direction === '') {
        this.sortQuests$$.next(sortedQuests);
        return;
      }

      this.sortQuests$$.next(
        quests.sort((a, b) => {
          if (this.displayedColumns.includes(sort.active)) {
            return compare(a[sort.active], b[sort.active], sort.direction === 'asc');
          }
          return 0;
        })
      );
    });
  }

  getQuestStatus(quest: QuestMetadata): string {
    if (quest.info?.public) {
      return this.transloco.translate('public');
    }
    if (quest.isDraft) {
      return this.transloco.translate('draft');
    }
    return this.transloco.translate('notPublic');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
