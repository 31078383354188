
import {QuestStepObjectiveMetadata} from './quest-objectives.interfaces';
import {
  ImgInfoBody,
  QuestInfo,
  QuestInfoParagraph,
} from './quest-info.interfaces';
import {QuestAccessLevel} from './quest-core.enums';
import {GeoCoords, QGraph, QGraphVertexWithRadius} from './geo-and-movement.interfaces';
import {Dict, TimeSpan} from './common.interfaces';
import {QuestUser} from './social.interfaces';
import {Organization} from './user.interfaces';
import {Encounter, Precondition} from './encounter.interfaces';

export interface QuestMetadata {
  id?: number;
  name: string;
  description?: QuestInfoParagraph[];

  creator?: QuestUser;
  organization?: Organization;

  teamMode?: boolean;
  competitiveMode?: boolean;
  locationPoint?: GeoCoords;

  isDraft?: boolean;

  config?: QuestConfig;
  steps: QuestStepMetadata[];

  info?: QuestInfo;
  gameAccess?: QuestGameAccess[];
  accessLevel?: QuestAccessLevel;
  publicationRequest?: QuestPublicationRequest;

  createdAt?: Date;
  updatedAt?: Date;

  adaptive?: boolean;
  isDaily?: boolean;
}

export interface QuestStepMetadata {
  id?: number;
  name?: string;
  type?: string;

  actionZone?: QGraph;
  functionalMetadata: QuestStepFunctionalMetadata;
  finalDescription?: string;
}

export interface QuestConfig {
  actionZone?: QGraph;
  timer?: TimeSpan;
  hazardZones?: QGraph[];
  stepsHidden?: boolean;
}

export interface QuestStepFunctionalMetadata {
  timer?: TimeSpan;
  hazardZones?: QGraph[];
  clueMetadata?: QuestInfoParagraph[];
  images?: ImgInfoBody[];
  objectives: QuestStepObjectiveMetadata[];
  startZone?: QGraph | QGraphVertexWithRadius;
  canBeSkipped?: boolean;
  encounter?: Encounter;
  precondition?: Precondition;
}

export interface QuestPublicationRequest {
  id?: number;
  questId?: number;
  status: 'pending' | 'approved' | 'rejected';
  updatedAt?: Date;
  createdAt?: Date;
}

export interface QuestGameAccess {
  id?: number;
  accessType: 'level' | 'tokens' | 'onlyOneCompletion';
  config: Dict<any>;
}

export const isStartZoneCircle = (obj: any): obj is QGraphVertexWithRadius => {
  return !!obj?.coords;
}

//TODO where to put radiusForCatch?
