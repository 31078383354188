import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StepperService} from './stepper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {distinctUntilKeyChanged, filter, Subject, takeUntil} from 'rxjs';
import {MAP_EDITOR_URL_FRAGMENT} from '../../constants/nav.constants';

export interface StepperStep {
  name: string;
  label: string;
  completed?: boolean;
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input() steps: StepperStep[] = [];

  private destroy$ = new Subject<void>();

  constructor(public stepperService: StepperService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.stepperService.onManualSwitchStep().subscribe((value) => {
      this.stepperService.currentStep$.next(this.steps[value]);
    });

    if (this.steps && this.steps.length) {
      this.stepperService.currentStep$.next(this.steps[0]);
    }

    this.stepperService.moveByStep$.subscribe((value) => {
      const currentIndex = this.steps.indexOf(this.stepperService.currentStep$.value);

      if (value) {
        if (currentIndex + 1 == this.steps.length) {
          throw Error('Can not go to the next step. Current step is the last one');
        }
      } else {
        if (currentIndex == 0) {
          throw Error('Can not go to the previous step. Current step is the first one');
        }
      }

      const move = value ? 1 : -1;

      this.stepperService.currentStep$.next(this.steps[currentIndex + move]);
    });

    this.stepperService.currentStep$.pipe(distinctUntilKeyChanged('name')).subscribe((value) => {
      this.router.navigate([`./`], {relativeTo: this.activatedRoute, fragment: value.name});
    });

    this.activatedRoute.fragment.pipe(filter(value => value !== MAP_EDITOR_URL_FRAGMENT), takeUntil(this.destroy$))
      .subscribe((value) => {
        const step = this.steps.find((step) => step.name === value);
        if (step && step.name !== this.stepperService.currentStep$.value?.name) {
          if (this.canStepBeClicked(this.steps.indexOf(step))) {
            this.stepperService.currentStep$.next(step);
          } else {
            this.router.navigate([`./`], {relativeTo: this.activatedRoute, fragment: this.steps[0].name});
          }
        }
      });
  }

  clickOnStep(index: number): void {
    if (this.canStepBeClicked(index)) {
      this.stepperService.currentStep$.next(this.steps[index]);
    }
  }

  private canStepBeClicked(index: number): boolean {
    const currentStep = this.steps[index];
    const prevStep = this.steps[index - 1];

    return currentStep.completed || (prevStep.completed && this.steps.length !== index);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
