<ng-container *transloco="let t">
  <div class="container">
    <div class="left-side">
      <div class="general-text text-color-soft row">{{t('general').toUpperCase()}}</div>
      <h2 class="row">{{quest.name}}</h2>
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/project-icons/' + questTypeImgName + '.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('questType')}}</div>
          <div class="general-big-text">
            {{prettyQuestTypeName}}
            <!--          <app-tooltip [title]="'Test'"-->
            <!--                       [paragraphs]="['A single quest can only be played by a single player']">-->
          <!--          </app-tooltip>-->
        </div>
      </div>
    </div>

    @if (quest.organization) {
      <div class="row info-container">
        <div class="img-container raw-svg-container">
          @if (quest.organization.secondaryLogo.startsWith('http')) {
            <img [src]="orgLogo" alt="">
          } @else {
            <div class="raw-svg-container" [innerHTML]="orgLogo"></div>
          }
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('onBehalfOfOrganization')}}</div>
          <div class="general-big-text">
            {{quest.organization.name}}
          </div>
        </div>
      </div>
    }

    <div class="row info-container">
      <div class="img-container">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/project-icons/' + privacyTypeImdName + '.svg#c'"></use>
        </svg>
      </div>
      <div class="text-container">
        <div class="general-text text-color-soft">{{t('privacy')}}</div>
        <div class="general-big-text">
          {{privacyTypeName}}
        </div>
      </div>
    </div>

    <div class="row info-container">
      <div class="img-container">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/project-icons/dashboard.svg#c'"></use>
        </svg>
      </div>
      <div class="text-container">
        <div class="general-text text-color-soft">{{t('recommendedFor')}}</div>
        <div class="general-big-text">
          {{t(quest.info.recommendedFor)}}
        </div>
      </div>
    </div>

    <div class="row info-container">
      <div class="img-container">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/project-icons/calendar.svg#c'"></use>
        </svg>
      </div>
      <div class="text-container">
        <div class="general-text text-color-soft">{{t('available')}}</div>
        <div class="general-big-text">
          {{availability}}
        </div>
      </div>
    </div>

    @if (timer) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 20 20">
            <use [attr.xlink:href]="'assets/project-icons/clock.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('timer')}}</div>
          <div class="general-big-text">
            {{timer}}
          </div>
        </div>
      </div>
    }

    @if (quest.description) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 20 20">
            <use [attr.xlink:href]="'assets/project-icons/book.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('description')}}</div>
          <div class="general-big-text">
            @for (par of questDescription; track par) {
              <div>
                {{par}}
              </div>
            }
          </div>
        </div>
      </div>
    }

    <div class="separator light row"></div>
    <div class="general-text text-color-soft row">{{t('options').toUpperCase()}}</div>

    <div class="row info-container">
      <div class="img-container">
        <svg viewBox="0 0 20 20">
          <use [attr.xlink:href]="'assets/project-icons/flash.svg#c'"></use>
        </svg>
      </div>
      <div class="text-container">
        <div class="general-text text-color-soft">{{t('difficulty')}}</div>
        <div class="general-big-text">
          {{questDifficulty}}
        </div>
      </div>
    </div>

    <div class="row info-container">
      <div class="img-container">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/project-icons/location.svg#c'"></use>
        </svg>
      </div>
      <div class="text-container">
        <div class="general-text text-color-soft">{{t('address')}}</div>
        <div class="general-big-text">
          {{quest.info?.address ? quest.info?.address : '-'}}
        </div>
      </div>
    </div>

    <div class="row info-container">
      <div class="img-container">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/project-icons/lock.svg#c'"></use>
        </svg>
      </div>
      <div class="text-container">
        <div class="general-text text-color-soft">{{t('onlyOneCompletion')}}</div>
        <div class="general-big-text">
          {{onlyOneCompletion ? t('yes') : t('no')}}
        </div>
      </div>
    </div>

    @if (levelRequired) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/project-icons/lock.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('levelRequired')}}</div>
          <div class="general-big-text">
            {{levelRequired.config['level']}}
          </div>
        </div>
      </div>
    }

    @if (quest.info?.approxDistance) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 20 20">
            <use [attr.xlink:href]="'assets/project-icons/double-arrow.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('distance')}}</div>
          <div class="general-big-text">
            {{approxDistance}}
          </div>
        </div>
      </div>
    }

    @if (approxTime) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 20 20">
            <use [attr.xlink:href]="'assets/project-icons/clock.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('time')}}</div>
          <div class="general-big-text">
            {{approxTime}}
          </div>
        </div>
      </div>
    }

    @if (quest.info?.tags && quest.info?.tags.length) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/project-icons/tag.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('tags')}}</div>
          <div>
            @for (tag of quest.info?.tags; track tag) {
              <app-tag [text]="tag.name" style="cursor: default"></app-tag>
            }
          </div>
        </div>
      </div>
    }

    @if (quest.info?.images && quest.info?.images?.length) {
      <div class="row">
        @for (url of quest.info?.images; track url) {
          <div class="img-thumbnail">
            <img [src]="url.url ? url.url : url.base" alt="">
          </div>
        }
      </div>
    }
  </div>
  <div class="right-side">
    <div class="general-text text-color-soft row">{{t('steps').toUpperCase()}}</div>

    @if (quest.config?.stepsHidden) {
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/project-icons/view-off.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('theStepsHidden')}}</div>
        </div>
      </div>
    }

    @for (step of quest.steps; track step; let i = $index) {
      @if (i !== 0) {
        <div class="separator light row"></div>
      }
      <h2 class="row">{{i + 1}}. {{step.name}}</h2>
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 20 20">
            <use [attr.xlink:href]="'assets/project-icons/map.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('objectiveType')}}</div>
          <div class="general-big-text">
            {{stepsInfo[i].objectiveType}}
          </div>
        </div>
      </div>
      @if (step.functionalMetadata.timer) {
        <div class="row info-container">
          <div class="img-container">
            <svg viewBox="0 0 20 20">
              <use [attr.xlink:href]="'assets/project-icons/clock.svg#c'"></use>
            </svg>
          </div>
          <div class="text-container">
            <div class="general-text text-color-soft">{{t('timer')}}</div>
            <div class="general-big-text">
              {{stepsInfo[i].timer}}
            </div>
          </div>
        </div>
      }
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/project-icons/check.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('task')}}</div>
          <div class="general-big-text">
            @for (par of stepsInfo[i].stepDescription; track par) {
              <div>
                {{par}}
              </div>
            }
          </div>
        </div>
      </div>
      @if (step.functionalMetadata.images && step.functionalMetadata.images.length) {
        <div class="row">
          @for (url of step.functionalMetadata.images; track url) {
            <div class="img-thumbnail">
              <img [src]="url.url ? url.url : url.base" alt="">
            </div>
          }
        </div>
      }
      <div class="row info-container">
        <div class="img-container">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/project-icons/book.svg#c'"></use>
          </svg>
        </div>
        <div class="text-container">
          <div class="general-text text-color-soft">{{t('finalDescription')}}</div>
          <div class="general-big-text">
            @for (par of stepsInfo[i].finalDescription; track par) {
              <div>
                {{par}}
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>
</div>
</ng-container>
