import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalSpinnerService {

  isShowSpinner = signal(false);

  constructor() { }

  hideSpinner(): void {
    this.isShowSpinner.set(false);
  }

  showSpinner(): void {
    this.isShowSpinner.set(true);
  }
}
