import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  constructor(private router: Router) {
    // fragment: 'user-modal' is not passed, but not mush I can do about it
    this.router.navigate(['my-quests'], {fragment: 'user-modal'});
  }
}
