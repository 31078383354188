import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, tap} from 'rxjs';
import {QuestsCrudService} from 'quest-atlas-angular-components';
import {MY_QUESTS_PATH} from '../app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class QuestPermissionsGuardService {

  constructor(private questService: QuestsCrudService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.paramMap.get('id') === 'new') {
      return true;
    }

    return this.questService.canEditQuest$(Number(route.paramMap.get('id'))).pipe(
      tap(canEdit => {
        if (!canEdit) {
          this.router.navigate([MY_QUESTS_PATH])
        }
      })
    );
  }
}
