<div class="container" *transloco="let t">
  <div class="full-width-container flex flex-row-reverse">
    <button mat-flat-button color="primary" routerLink="/obelisks-editor/new">{{t('addNewPlace')}}</button>
  </div>
  <app-obelisks-table-list
    [obelisks]="obelisks"
    (select)="goToEditObelisk($event)"
    (edit)="goToEditObelisk($event)"
    (delete)="handleDeleteObelisk($event)"
  >
  </app-obelisks-table-list>

  @if (showDeleteConfirmation()) {
    <app-modal
      [title]="t('areYouSureQ')"
      [footerButtons]="modalButtons"
      (closeEvent)="showDeleteConfirmation.set(false); obeliskToDelete.set(null)">
      <p class="text-color-soft">{{t('areYouSureToDelete')}}</p>
    </app-modal>
  }
</div>
