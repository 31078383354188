import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Dict} from 'quest-atlas-angular-components';
import {ODM} from 'quest-atlas-angular-components';
import {BehaviorSubject, filter, map, merge, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ODMService {
  private readonly BASE_URL = `${environment.API_URL}/users/odms`;
  private lastUnreadODMCount$ = new BehaviorSubject<number>(null)

  constructor(private http: HttpClient) { }

  getODMs$(): Observable<ODM[]> {
    return this.http.get<Dict<any>[]>(this.BASE_URL).pipe(
      map(value => value.map(this.transformFromServer))
    );
  }

  getUnreadODMsCount$(): Observable<number> {
    return merge(
      this.http.get<Dict<any>[]>(`${this.BASE_URL}/unread-count`).pipe(
        map(value => value['count']),
        tap(value => this.lastUnreadODMCount$.next(value))
      ),
      this.lastUnreadODMCount$.pipe(filter(value => value !== null))
    );
  }

  markAsRead$(odmId: number): Observable<unknown> {
    return this.http.patch<unknown>(`${this.BASE_URL}/${odmId}/mark-as-read`, {}).pipe(
      tap(() => {
        if (this.lastUnreadODMCount$.value === null) {
          return;
        }

        this.lastUnreadODMCount$.next(this.lastUnreadODMCount$.value - 1);
      })
    );
  }

  private transformFromServer(data: Dict<any>): ODM {
    return {
      id: data['id'],
      sender: data['sender'],
      type: data['type'],
      message: data['message'],
      suppliedData: data['supplied_data'],
      createdAt: new Date(data['created_at']),
      isRead: data['is_read'],
    }
  }
}
