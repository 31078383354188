<mat-toolbar class="toolbar top element-shadow">
  <button class="secondary-bar-btn footer-button"
          (click)="navigateToHome()">
    <svg viewBox="0 0 24 24" width="16" height="16" style="transform: rotate(180deg)">
      <use xlink:href="assets/project-icons/arrow.svg#a"></use>
    </svg>
    Back
  </button>
  <h3>PRIVACY POLICY</h3>
</mat-toolbar>

<div class="container">
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c10 c1">Last updated June 22, 2023</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c8">This privacy notice for QuestAtlas (&quot;</span><span class="c1">Company</span><span
    class="c8">,&quot; &quot;</span><span class="c1">we</span><span class="c8">,&quot; &quot;</span><span
    class="c1">us</span><span class="c8">,&quot; or &quot;</span><span class="c1">our</span><span class="c8">&quot;), describes how and why we might collect, store, use, and/or share (&quot;</span><span
    class="c1">process</span><span class="c8">&quot;) your information when you use our services (&quot;</span><span
    class="c1">Services</span><span class="c6">&quot;), such as when you:</span></p>
  <ul class="c3 lst-kix_qn54p62wv5og-0 start">
    <li class="c12 li-bullet-0"><span class="c6">Download and use our mobile application (QuestAtlas), or any other application of ours that links to this privacy notice</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_8dgd743opfp0-0 start">
    <li class="c12 li-bullet-0"><span class="c6">Engage with us in other related ways, including any sales, marketing, or events</span>
    </li>
  </ul>
  <p class="c0"><span class="c1">Questions or concerns? </span><span class="c6">Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at contact.questatlas&#64;gmail.com.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.lhr6ro8p0e9k"><span>SUMMARY OF KEY POINTS</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our </span><span
    class="c7 c14 c29"><a class="c16" routerLink="./" fragment="h.rov2c0sx456l">table of contents</a></span><span
    class="c1 c24 c14 c30">&nbsp;below to find the section you are looking for.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">What personal information do we process?</span><span class="c8">&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with QuestAtlas and the Services, the choices you make, and the products and features you use. Learn more about </span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.63yeetssac9h">personal information you disclose to us</a></span><span
    class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">Do we process any sensitive personal information?</span><span class="c6">&nbsp;We do not process sensitive personal information.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">Do we receive any information from third parties?</span><span class="c6">&nbsp;We do not receive any information from third parties.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">How do we process your information?</span><span class="c8">&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about </span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.ezkerbgjhzf6">how we process your information</a></span><span
    class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">In what situations and with which parties do we share personal information?</span><span
    class="c8">&nbsp;We may share information in specific situations and with specific third parties. Learn more about </span><span
    class="c7"><a class="c16"
                  routerLink="./" fragment="h.iruwmkyzqtjl">when and with whom we share your personal information</a></span><span
    class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">How do we keep your information safe?</span><span class="c8">&nbsp;We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about </span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.mcep43kxp61b">how we keep your information safe</a></span><span
    class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">What are your rights?</span><span class="c8">&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about </span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.jw2gzkgllyy1">your privacy rights</a></span><span class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">How do you exercise your rights?</span><span class="c6">&nbsp;The easiest way to exercise your rights is by visiting contact.questatlas&#64;gmail.com, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span
    class="c8">Want to learn more about what QuestAtlas does with any information we collect? </span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.63yeetssac9h">Review the privacy notice in full.</a></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.rov2c0sx456l"><span class="c10 c17">TABLE OF CONTENTS</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.63yeetssac9h">1. WHAT INFORMATION DO WE COLLECT?</a></span></p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.ezkerbgjhzf6">2. HOW DO WE PROCESS YOUR INFORMATION?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.8svqqk65f4f6">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.iruwmkyzqtjl">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.s4gf4980ohfs">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.ij8mkuqm0ock">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.mcep43kxp61b">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.jw2gzkgllyy1">8. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.sdu2vg4b60a4">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.kikwe7dikijs">10. DO WE MAKE UPDATES TO THIS NOTICE?</a></span>
  </p>
  <p class="c0"><span class="c20 c25"><a class="c16"
                                         routerLink="./" fragment="h.1kkgjlyo3rvf">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></span>
  </p>
  <p class="c0"><span class="c7"><a class="c16" routerLink="./" fragment="h.mpdtnt97tljl">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.63yeetssac9h"><span class="c10 c17">1. WHAT INFORMATION DO WE COLLECT?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c10 c27">Personal information you disclose to us</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">We collect personal information that you provide to us.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">Personal Information Provided by You.</span><span class="c6">&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span>
  </p>
  <ul class="c3 lst-kix_3ahnntcxnd4-0 start">
    <li class="c12 li-bullet-0"><span class="c6">email addresses</span></li>
  </ul>
  <ul class="c3 lst-kix_fv7n1b5hjw6-0 start">
    <li class="c12 li-bullet-0"><span class="c6">usernames</span></li>
  </ul>
  <ul class="c3 lst-kix_ffrvph7xdoyb-0 start">
    <li class="c12 li-bullet-0"><span class="c6">passwords</span></li>
  </ul>
  <ul class="c3 lst-kix_un0gj62wjrli-0 start">
    <li class="c12 li-bullet-0"><span class="c6">names</span></li>
  </ul>
  <p class="c0"><span class="c1">Sensitive Information.</span><span class="c6">&nbsp;We do not process sensitive information.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">Social Media Login Data. </span><span class="c8">We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called &quot;</span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.s4gf4980ohfs">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></span><span
    class="c6">&quot; below.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1">Application Data.</span><span class="c23 c22 c24">&nbsp;If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</span>
  </p>
  <ul class="c3 lst-kix_t62mg2drxvz6-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Geolocation Information.</span><span class="c23 c22 c24">&nbsp;We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&#39;s settings.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_c4qj1aryqwdx-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Mobile Device Access.</span><span class="c6">&nbsp;We may request access or permission to certain features from your mobile device, including your mobile device&#39;s sensors, social media accounts, and other features. If you wish to change our access or permissions, you may do so in your device&#39;s settings.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_xhopxzmct3pt-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Mobile Device Data.</span><span class="c6">&nbsp;We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device&rsquo;s operating system or platform, the type of mobile device you use, your mobile device&rsquo;s unique device ID, and information about the features of our application(s) you accessed.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_tyh9kvt1n41b-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Push Notifications.</span><span class="c6">&nbsp;We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device&#39;s settings.</span>
    </li>
  </ul>
  <p class="c0"><span class="c6">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c10 c27">Information automatically collected</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">The information we collect includes:</span></p>
  <ul class="c3 lst-kix_ud18f8cv0qo-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Log and Usage Data.</span><span class="c8">&nbsp;Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services</span><span
      class="c22">&nbsp;</span><span class="c6">(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called &quot;crash dumps&quot;), and hardware settings).</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_fdrqfiau1jfv-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Device Data.</span><span class="c6">&nbsp;We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_4wurejpzvpen-0 start">
    <li class="c12 li-bullet-0"><span class="c8 c14">Location Data.</span><span class="c6">&nbsp;We collect location data such as information about your device&#39;s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</span>
    </li>
  </ul>
  <h1 class="c0" id="h.ezkerbgjhzf6"><span class="c10 c17">2. HOW DO WE PROCESS YOUR INFORMATION?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c10">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span>
  </p>
  <ul class="c3 lst-kix_6rd09cxugtm9-0 start">
    <li class="c12 li-bullet-0"><span class="c1">To facilitate account creation and authentication and otherwise manage user accounts. </span><span
      class="c6">We may process your information so you can create and log in to your account, as well as keep your account in working order.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_26fd2sxxcl8h-0 start">
    <li class="c12 li-bullet-0"><span class="c1">To save or protect an individual&#39;s vital interest.</span><span
      class="c23 c22 c24">&nbsp;We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</span>
    </li>
  </ul>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.8svqqk65f4f6"><span
    class="c10 c17">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14 c19">If you are located in the EU or UK, this section applies to you.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span>
  </p>
  <ul class="c3 lst-kix_lkvbrs6b7v7s-0 start">
    <li class="c12 li-bullet-0"><span class="c1">Consent. </span><span class="c8">We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about </span><span
      class="c7"><a class="c16" routerLink="./" fragment="h.sdu2vg4b60a4">withdrawing your consent</a></span><span
      class="c6">.</span></li>
  </ul>
  <ul class="c3 lst-kix_kvds7sbmjejm-0 start">
    <li class="c12 li-bullet-0"><span class="c1">Legal Obligations.</span><span class="c23 c22 c24">&nbsp;We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_wpvf2u6tof7n-0 start">
    <li class="c12 li-bullet-0"><span class="c1">Vital Interests.</span><span class="c10 c11">&nbsp;We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span>
    </li>
  </ul>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c19 c1 c14">If you are located in Canada, this section applies to you.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c22">We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can </span><span
    class="c20 c25"><a class="c16" routerLink="./" fragment="h.sdu2vg4b60a4">withdraw your consent</a></span><span class="c10 c11">&nbsp;at any time.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c23 c22 c24">In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</span>
  </p>
  <ul class="c3 lst-kix_e0n6vbr2skyl-0 start">
    <li class="c12 li-bullet-0"><span class="c23 c22 c24">If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_yydbvpnz5n33-0 start">
    <li class="c12 li-bullet-0"><span class="c10 c11">For investigations and fraud detection and prevention</span></li>
  </ul>
  <ul class="c3 lst-kix_lxk2y9p3c2dp-0 start">
    <li class="c12 li-bullet-0"><span
      class="c23 c22 c24">For business transactions provided certain conditions are met</span></li>
  </ul>
  <ul class="c3 lst-kix_ty6mvru3a42-0 start">
    <li class="c12 li-bullet-0"><span class="c10 c11">If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_gehn5vkovpgg-0 start">
    <li class="c12 li-bullet-0"><span class="c10 c11">For identifying injured, ill, or deceased persons and communicating with next of kin</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_5aez25dnkeot-0 start">
    <li class="c12 li-bullet-0"><span class="c23 c22 c24">If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_vyom8qebwl0v-0 start">
    <li class="c12 li-bullet-0"><span class="c10 c11">If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_mg88484t0oko-0 start">
    <li class="c12 li-bullet-0"><span class="c23 c22 c24">If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_gzdk8x5a569f-0 start">
    <li class="c12 li-bullet-0"><span class="c6">If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_sdnbu0z6hmsa-0 start">
    <li class="c12 li-bullet-0"><span class="c6">If the collection is solely for journalistic, artistic, or literary purposes</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_pbzx6nfi9oyx-0 start">
    <li class="c12 li-bullet-0"><span class="c6">If the information is publicly available and is specified by the regulations</span>
    </li>
  </ul>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.iruwmkyzqtjl"><span
    class="c10 c17">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short:</span><span class="c8 c13">&nbsp;We may share information in specific situations described in this section and/or with the following third parties.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We may need to share your personal information in the following situations:</span></p>
  <ul class="c3 lst-kix_qlvhi62xsrpk-0 start">
    <li class="c12 li-bullet-0"><span class="c1">Business Transfers.</span><span class="c6">&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_h7f9wui27peu-0 start">
    <li class="c12 li-bullet-0"><span class="c1">Business Partners.</span><span class="c10 c11">&nbsp;We may share your information with our business partners to offer you certain products, services, or promotions.</span>
    </li>
  </ul>
  <ul class="c3 lst-kix_p9elbkz6kton-0 start">
    <li class="c12 li-bullet-0"><span class="c1">Offer Wall. </span><span class="c23 c22 c24">Our application(s) may display a third-party hosted &quot;offer wall.&quot; Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</span>
    </li>
  </ul>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.s4gf4980ohfs"><span class="c10 c17">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.ij8mkuqm0ock"><span class="c10 c17">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.mcep43kxp61b"><span class="c10 c17">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">We aim to protect your personal information through a system of organizational and technical security measures.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.jw2gzkgllyy1"><span class="c10 c17">8. WHAT ARE YOUR PRIVACY RIGHTS?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short:</span><span class="c13 c8">&nbsp;In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c8">In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section &quot;</span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.1kkgjlyo3rvf">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></span><span
    class="c6">&quot; below.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We will consider and act upon any request in accordance with applicable data protection laws.</span>
  </p>
  <p class="c0"><span class="c23 c22 c24">&nbsp;</span></p>
  <p class="c0"><span class="c8">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your </span><span
    class="c9"><a class="c16" href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1688545170527608&amp;usg=AOvVaw1PQyhAdbDfPPwlJYxdzFiS">Member State data protection authority</a></span><span
    class="c8">&nbsp;or </span><span class="c18"><a class="c16" href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1688545170527980&amp;usg=AOvVaw2aXGOU4XmHichxuTfC7lib">UK data protection authority</a></span><span
    class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c8">If you are located in Switzerland, you may contact the </span><span class="c18"><a
    class="c16"
    href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1688545170528377&amp;usg=AOvVaw2QmoQAN-d611REpeAFAz1r">Federal Data Protection and Information Commissioner</a></span><span
    class="c6">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c20">Withdrawing your consent:</span><span class="c8">&nbsp;If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section &quot;</span><span
    class="c7"><a class="c16" routerLink="./" fragment="h.1kkgjlyo3rvf">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></span><span
    class="c6">&quot; below.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c10 c27">Account Information</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c10 c11">If you would at any time like to review or change the information in your account or terminate your account, you can:</span>
  </p>
  <ul class="c3 lst-kix_3pnzahd5mp87-0 start">
    <li class="c12 li-bullet-0"><span class="c6">Contact us using the contact information provided.</span></li>
  </ul>
  <ul class="c3 lst-kix_rvt9ysccvqrl-0 start">
    <li class="c12 li-bullet-0"><span class="c6">Log in to your account settings and update your user account.</span>
    </li>
  </ul>
  <p class="c0"><span class="c6">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c22">If you have questions or comments about your privacy rights, you may email us at </span><span
    class="c8">contact.questatlas&#64;gmail.com</span><span class="c23 c22 c24">.</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.sdu2vg4b60a4"><span class="c10 c17">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span>
  </p>
  <p class="c0 c2"><span class="c6"></span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.kikwe7dikijs"><span class="c10 c17">10. DO WE MAKE UPDATES TO THIS NOTICE?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c1 c14">In Short: </span><span class="c13 c8">Yes, we will update this notice as necessary to stay compliant with relevant laws.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">We may update this privacy notice from time to time. The updated version will be indicated by an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.1kkgjlyo3rvf"><span class="c10 c17">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">If you have questions or comments about this notice, you may email us at contact.questatlas&#64;gmail.com or contact us by post at:</span>
  </p>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">QuestAtlas</span></p>
  <p class="c0"><span class="c6">Kulparkivska 93</span></p>
  <p class="c0"><span class="c8">Lviv</span><span class="c22">, </span><span class="c8">Lviv Oblast</span><span
    class="c22">&nbsp;</span><span class="c6">79021</span></p>
  <p class="c0"><span class="c6">Ukraine</span></p>
  <p class="c0 c2"><span class="c5"></span></p>
  <h1 class="c0" id="h.mpdtnt97tljl"><span class="c10 c17">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
  </h1>
  <p class="c0 c2"><span class="c5"></span></p>
  <p class="c0"><span class="c6">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information,
    please visit: <a href="https://app.questatlas.world">app.questatlas.world</a>.</span>
  </p>
  <p class="c0 c2"><span class="c10 c21"></span></p>
</div>
