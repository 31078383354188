import { Component } from '@angular/core';
import {ODMService} from '../../services/odm.service';
import {shareReplay, tap} from 'rxjs';

@Component({
  selector: 'app-odm-list',
  templateUrl: './odm-list.component.html',
  styleUrls: ['./odm-list.component.scss']
})
export class OdmListComponent {

  odms$ = this.odmService.getODMs$().pipe(tap(value => console.log('odms', value)), shareReplay(1));

  constructor(private odmService: ODMService) { }
}
