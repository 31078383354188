<div class="form-container single" *transloco="let t">
  @if (form) {
    <form [formGroup]="form">
      <mat-form-field appearance="fill" class="form-field margin-bottom-24" color="accent">
        <mat-label>{{t('questName')}}</mat-label>
        <input matInput formControlName="name" maxlength="41" />
      </mat-form-field>
<!--      <app-dropdown [title]="t('questType') + ' *'" class="margin-bottom-24" [options]="questTypeOptions" formControlName="mode"></app-dropdown>-->
      <app-dropdown [title]="t('recommendedFor') + ' *'" class="margin-bottom-24" [options]="recommendedForOptions" formControlName="recommendedFor"></app-dropdown>
      @if (organizationsOptions.length) {
        <app-dropdown [title]="t('onBehalfOfOrganization')" [options]="organizationsOptions" [optional]="true" formControlName="organizationId"></app-dropdown>
      }
      <!--    <app-dropdown class="margin-bottom-24" [title]="'Privacy *'" [options]="privacyOptions" formControlName="privacy"></app-dropdown>-->
    </form>
  }
</div>
