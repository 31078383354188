<div class="overlay">
  <div class="modal" [ngClass]="size">
    <header class="modal-header">
      <span class="title">{{title}}</span>
      <img class="pointer" src="assets/project-icons/close.svg" alt="X" (click)="closeEvent.emit()">
    </header>
    <div class="modal-content">
      <ng-content></ng-content>
    </div>
    <footer class="modal-footer" [ngClass]="size">
      @for (btn of footerButtons; track btn) {
        <button (click)="btn.onClick()"
          [ngClass]="btn.type === 'primary' ? 'primary-btn' : (btn.type === 'secondary' ? 'secondary-btn' : 'warning-btn')"
          >
          {{btn.text}}
        </button>
      }
    </footer>
  </div>
</div>
