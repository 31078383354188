<div class="container">
  <div id="back" class="headers-row secondary-text cursor-pointer" (click)="goBack()">
    <svg class="regular-img-size" style="transform: rotate(180deg)">
      <use xlink:href="assets/project-icons/arrow.svg#a"></use>
    </svg>
    Back
  </div>
  <div id="header-toolbar" class="headers-row">
    @if (status() === 'pending') {
      <button class="warning-bar-btn footer-button margin-right-4" (click)="rejectPublishRequest()">
        Reject
      </button>
      <button class="primary-bar-btn footer-button margin-right-12" (click)="approvePublishRequest()">
        Approve
      </button>
    } @else {
      <span class="color-green margin-right-4" [ngClass]="{'color-red': status() === 'rejected'}">
        {{status() === 'rejected' ? 'Rejected' : 'Approved'}}
      </span>
    }
    <button class="primary-bar-btn footer-button" (click)="navigateToEditor(quest)">
      View in editor
    </button>
  </div>
  @if (quest) {
    <app-quest-view [quest]="quest"></app-quest-view>
  }
</div>
