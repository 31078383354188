import {translateObject} from '@ngneat/transloco';

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function metersToPretty(meters: number): string {
  if (!meters && meters !== 0) {
    return '-';
  }

  if (meters < 1000) {
    return `${meters} ${translateObject('meterShort')}`;
  }

  return `${(meters/1000).toFixed(1)} ${translateObject('kilometerShort')}`
}

export const numberToAtLeastTwoCharsStr = (n?: number): string => {
  if (n === undefined) {
    return '00';
  }

  if (n < 0) {
    return `${n}`;
  }

  if (n < 10) {
    return `0${n}`;
  }
  return `${n}`;
};
