@if (quest()) {
  @if (quest().accessLevel === QuestAccessLevel.own || quest().accessLevel === QuestAccessLevel.collaborator) {
    <div id="header-toolbar" class="headers-row" *transloco="let t">
      <button class="primary-bar-btn footer-button" (click)="navigateToEditor(quest())">
        <svg class="margin-right-4" viewBox="0 0 24 24" width="16" height="16">
          <use xlink:href="assets/project-icons/pencil.svg#c"></use>
        </svg>
        {{t('edit')}}
      </button>
    </div>
  }
  <app-quest-view [quest]="quest()"></app-quest-view>
}
