import {Component, EventEmitter, Input, Output} from '@angular/core';


export interface ChooserOption {
  name: string;
  value: any;
  chosen?: boolean;
}


@Component({
  selector: 'app-chooser',
  templateUrl: './chooser.component.html',
  styleUrls: ['./chooser.component.scss']
})
export class ChooserComponent {

  @Input() options: ChooserOption[] = [];

  @Output() optionChosen = new EventEmitter<ChooserOption>();

  choose(option: ChooserOption) {
    this.options.forEach(o => o.chosen = false);
    option.chosen = true;

    this.optionChosen.emit(option);
  }
}
