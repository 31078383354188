import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GeoCoords} from 'quest-atlas-angular-components';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {FeatureCollection} from 'geojson';

@Injectable({
  providedIn: 'root'
})
export class MapboxSpecificService {

  constructor(private http: HttpClient) { }

  reverseGeocoding(coords: GeoCoords): Observable<any> {
    return this.http.get<any>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.lng},${coords.lat}.json`,
      {params: {access_token: environment.mapboxToken, routing: true}})
  }

  forwardGeocoding(search: string): Observable<any> {
    return this.http.get<any>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json`,
      {params: {access_token: environment.mapboxToken, routing: true, autocomplete: false}});
  }

  isochrone(coords: GeoCoords, minutes: number): Observable<FeatureCollection> {
    return this.http.get<any>(`https://api.mapbox.com/isochrone/v1/mapbox/walking/${coords.lng},${coords.lat}`,
      {params: {contours_minutes: minutes, generalize: 10, polygons: false, access_token: environment.mapboxToken}})
  }
}
