import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {UserService} from '../services/user.service';
import {map, Observable, take} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuperuserGuardService {

  constructor(private userService: UserService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getUserInfo$().pipe(
      take(1),
      map(user => user.isSuperUser)
    );
  }
}
