import {QuestDropdownValues} from '../../common-components/dropdown/dropdown.component';
import {QuestDifficulty, QuestMode, RecommendedFor} from 'quest-atlas-angular-components';
import {QuestStepObjectiveType} from 'quest-atlas-angular-components';
import {translateObject} from '@ngneat/transloco';

export const getQuestTypeOptions = (): QuestDropdownValues[] => ([
  {
    title: translateObject('single') as string,
    description: translateObject('singleDescription') as string,
    imgName: 'user',
    value: QuestMode.single
  },
  {
    title: translateObject('group') as string,
    description: translateObject('groupDescription') as string,
    imgName: 'users',
    value: QuestMode.group,
    disabled: true
  },
  {
    title: translateObject('competitive') as string,
    description: translateObject('competitiveDescription') as string,
    imgName: 'award',
    value: QuestMode.competitive,
    disabled: true
  }
]);

export const getPrivacyOptions = (): QuestDropdownValues[] => ([
  {
    title: 'Public',
    description: 'Anyone can see an play the quest',
    imgName: 'planet',
    value: true
  },
  {
    title: 'Private',
    description: 'Only invited users can play the quest',
    imgName: 'lock',
    value: false
  }
]);

export const getApproxDistanceOptions = (): QuestDropdownValues[] => ([
  {
    title: '< 1km',
    value: 1
  },
  {
    title: '2km',
    value: 2
  },
  {
    title: '3km',
    value: 3
  },
  {
    title: '5km',
    value: 5
  },
  {
    title: '7km+',
    value: 7
  },
]);

export const getApproxTimeOptions = (): QuestDropdownValues[] => ([
  {
    title: '< 1 hour',
    value: 1
  },
  {
    title: '2+ hours',
    value: 2
  },
  {
    title: '5+ hours',
    value: 5
  }
]);

export const getRecommendedForOptions = (): QuestDropdownValues[] => ([
  {
    title: translateObject('walk') as string,
    value: RecommendedFor.walk
  },
  {
    title: translateObject('run') as string,
    value: RecommendedFor.run
  },
  {
    title: translateObject('hike') as string,
    value: RecommendedFor.hike
  },
  {
    title: translateObject('bike') as string,
    value: RecommendedFor.bike
  },
  {
    title: translateObject('car') as string,
    value: RecommendedFor.car
  }
]);

export const getDifficultyOptions = (): QuestDropdownValues[] => ([
  {
    title: translateObject('easy') as string,
    value: QuestDifficulty.easy
  },
  {
    title: translateObject('medium') as string,
    value: QuestDifficulty.medium
  },
  {
    title: translateObject('hard') as string,
    value: QuestDifficulty.hard
  },
  {
    title: translateObject('master') as string,
    value: QuestDifficulty.master
  }
]);

export const getObjectiveTypeOptions = (): QuestDropdownValues[] => ([
  {
    title: translateObject('place') as string,
    description: translateObject('placeDescription') as string,
    value: QuestStepObjectiveType.markerReach
  },
  {
    title: translateObject('hiddenPlace') as string,
    description: translateObject('hiddenPlaceDescription') as string,
    value: QuestStepObjectiveType.markerFind
  },
  {
    title: translateObject('adaptive') as string,
    description: '',
    value: QuestStepObjectiveType.adaptive
  }
  // воно буде ще дуууже не скоро готове
  // {
  //   title: 'Chase Marker',
  //   description: 'Marker is moving away from a player',
  //   value: QuestStepObjectiveType.markerChase,
  //   disabled: true
  // },
  // {
  //   title: 'Advanced',
  //   description: '...',
  //   value: QuestStepObjectiveType.serpentScalesAlgorithm,
  //   disabled: true
  // },
  // {
  //   title: 'Manual Approval',
  //   description: 'This step has to be manually approved',
  //   value: QuestStepObjectiveType.manualApproval,
  //   disabled: true
  // }
]);

