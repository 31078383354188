<div class="quest-list-container" *transloco="let t">

  @if (loading()) {
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  } @else {
    @if ((quests$ | async)?.length) {
      <app-quests-table-list
        [quests$]="quests$"
        [showMenu]="true"
        (select)="navigateToQuest($event)"
        (edit)="navigateToEditQuest($event)"
        (delete)="handleQuestDelete($event.id)"
        (shareCollaborator)="copyShareTokenToClipboard($event.id, 'collaborator')"
        (sharePlayer)="copyShareTokenToClipboard($event.id, 'player')"
        >
      </app-quests-table-list>
    } @else {
      <div class="no-quests-label-container">
        <h2>{{type === 'my' ? t('noMyQuests') : t('noSharedQuests')}}</h2>
        <h4>{{type === 'my' ? t('noMyQuestsSuggestion') : t('noSharedQuestsSuggestion')}}</h4>
      </div>
    }
  }

  @if (showDeleteConfirmation) {
    <app-modal [title]="t('areYouSureQ')" [footerButtons]="modalButtons" (closeEvent)="showDeleteConfirmation = false">
      <p class="text-color-soft">{{t('areYouSureToDeleteQuest')}}</p>
    </app-modal>
  }
</div>
