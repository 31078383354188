import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';

@Injectable()
export class ErrorsInterceptorService implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar, private transloco: TranslocoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (req.url.includes('auth') && err.status !== 500) {
        return throwError(() => err);
      }

      if (err.status === 0) {
        this.snackBar.open(
          this.transloco.translate('noInternetErrorText'),
          this.transloco.translate('close'),
          {
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: 'error-snackbar'
          });
      } else
      if (err.status !== 401 && err.status !== 403) {
        this.snackBar.open(
          this.transloco.translate('generalErrorText'),
          this.transloco.translate('close'),
          {
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: 'error-snackbar'
          });
      }

      return throwError(() => err);
    }));
  }
}
