<div class="container" *transloco="let t">
  <div id="back" class="headers-row secondary-text cursor-pointer" (click)="goBack()">
    <svg class="regular-img-size" style="transform: rotate(180deg)">
      <use xlink:href="assets/project-icons/arrow.svg#a"></use>
    </svg>
    {{t('back')}}
  </div>
  <div id="header-toolbar" class="headers-row">
    @if (quest?.publicationRequest?.status !== 'approved') {
      <button class="footer-button margin-right-4"
        [ngClass]="getClassForPublishRequestButton()"
        (click)="createPublishRequest()"
        [disabled]="isPublishRequestDisabled()"
        >
        <svg class="margin-right-4" viewBox="0 0 24 24" width="16" height="16">
          <use xlink:href="assets/project-icons/planet.svg#c"></use>
        </svg>
        {{getTextForPublishRequestButton()}}
      </button>
    } @else {
      <span class="color-green margin-right-4">
        {{t('requestApproved')}}
      </span>
    }
    <button class="primary-bar-btn footer-button" (click)="navigateToEditor(quest)">
      <svg class="margin-right-4" viewBox="0 0 24 24" width="16" height="16">
        <use xlink:href="assets/project-icons/pencil.svg#c"></use>
      </svg>
      {{t('edit')}}
    </button>
  </div>
  @if (quest) {
    <app-quest-view [quest]="quest"></app-quest-view>
  }
</div>
