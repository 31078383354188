export enum QuestDifficulty {
  easy = 'EASY',
  medium = 'MEDIUM',
  hard = 'HARD',
  master = 'MASTER'
}

export enum RecommendedFor {
  walk = 'walk',
  run = 'run',
  hike = 'hike',
  bike = 'bike',
  car = 'car',
}

export enum QuestAvailabilityTypes {
  always = 'always',
  unavailable = 'unavailable',
  fromDate = 'fromDate',
  toDate = 'toDate',
  fromDateToDate = 'fromDateToDate',
  calendarDates = 'calendarDates',
  daysOfWeek = 'daysOfWeek',
  timeOfDay = 'timeOfDay',
  expiresAt = 'expiresAt',
  today = 'today'
}

export enum QuestMode {
  single = 'single',
  group = 'group',
  competitive = 'competitive'
}
