import {OnStepResult} from '../../projects/quest-atlas-angular-components/src/lib/interfaces/encounter.interfaces';

export function onMonacoLoad() {

  console.log((window as any).monaco);

  const monaco = (window as any).monaco;

  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemas: [{
      uri: 'http://myserver/foo-schema.json',
      fileMatch: ["*"],
      schema: {
        "$schema": "http://json-schema.org/draft-07/schema#",
        "type": "object",
        "properties": {
          "steps": {
            "type": "object",
            "additionalProperties": {
              "$ref": "#/definitions/EncounterStep"
            }
          },
          "initialStep": {
            "type": "string"
          },
          "introduction": {
            "type": "string"
          },
          "successfulEnd": {
            "type": "string"
          },
          "failedEnd": {
            "type": "string"
          }
        },
        "definitions": {
          "EncounterStep": {
            "type": "object",
            "properties": {
              "type": {
                "type": "string"
              },
              "metadata": {
                "oneOf": [
                  {"$ref": "#/definitions/EncounterStepMetadataQuiz"},
                  {"$ref": "#/definitions/EncounterStepMetadataDialogue"},
                  {"$ref": "#/definitions/EncounterStepMetadataStory"},
                  {"$ref": "#/definitions/EncounterStepMetadataAction"},
                  {"$ref": "#/definitions/EncounterStepMetadataRoll"}
                ]
              },
              "nextStep": {
                "type": "string"
              },
              "precondition": {
                "$ref": "#/definitions/Precondition"
              },
              "onStepResult": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/OnStepResult"
                }
              }
            }
          },
          "EncounterStepMetadataQuiz": {
            "type": "object",
            "properties": {
              "question": {
                "type": "string"
              },
              "answers": {
                "type": "array",
                "items": {
                  "type": "string"
                }
              },
              "correctAnswer": {
                "type": "string"
              },
              "attempts": {
                "type": "number"
              }
            }
          },
          "EncounterStepMetadataStory": {
            "type": "object",
            "properties": {
              "title": {
                "type": "string"
              },
              "text": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/ValueWithPrecondition"
                }
              },
              "image": {
                "type": "string"
              }
            }
          },
          "EncounterStepMetadataDialogue": {
            "type": "object",
            "properties": {
              "characterName": {
                "type": "string"
              },
              "phrases": {
                "type": "object",
                "additionalProperties": {
                  "$ref": "#/definitions/EncounterStepMetadataDialoguePhrase"
                }
              },
              "resolvedWith": {
                "type": "string"
              }
            }
          },
          "EncounterStepMetadataDialoguePhrase": {
            "type": "object",
            "properties": {
              "key": {
                "type": "string"
              },
              "characterPhrase": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/ValueWithPrecondition"
                }
              },
              "userOptions": {
                "type": "array",
                "items": {
                  "type": "object",
                  "properties": {
                    "text": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "leadsTo": {
                      "type": "string"
                    },
                    "chosen": {
                      "type": "boolean"
                    },
                    "nextStep": {
                      "type": "string"
                    },
                    "precondition": {
                      "$ref": "#/definitions/Precondition"
                    }
                  }
                }
              }
            }
          },
          "EncounterStepMetadataAction": {
            "type": "object",
            "properties": {
              "title": {
                "type": "string"
              },
              "text": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/ValueWithPrecondition"
                }
              },
              "image": {
                "type": "string"
              },
              "actions": {
                "type": "array",
                "items": {
                  "type": "object",
                  "properties": {
                    "text": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "nextStep": {
                      "type": "string"
                    },
                    "precondition": {
                      "$ref": "#/definitions/Precondition"
                    }
                  }
                }
              }
            }
          },
          "EncounterStepMetadataRoll": {
            "type": "object",
            "properties": {
              "rolls": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/SingleRoll"
                }
              },
              "rollsPrincipal": {
                "type": "string",
                "enum": ["all", "atLeastOne", "choice"]
              },
              "title": {
                "type": "string"
              },
              "text": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/ValueWithPrecondition"
                }
              },
              "nextStepOnSuccess": {
                "type": "string"
              },
              "nextStepOnFail": {
                "type": "string"
              },
              "failQuest": {
                "type": "boolean"
              }
            }
          },
          "SingleRoll": {
            "type": "object",
            "properties": {
              "characteristic": {
                "type": "string"
              },
              "basis": {
                "type": "number"
              },
              "modifiers": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/SingleRollModifier"
                }
              }
            }
          },
          "SingleRollModifier": {
            "type": "object",
            "properties": {
              "type": {
                "type": "string",
                "enum": ["highestRoll", "lowestRoll", "reroll", "plus", "minus"]
              },
              "value": {
                "type": "number"
              }
            }
          },
          "Precondition": {
            "type": "object",
            "properties": {
              "availableIf": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/SingleCondition"
                }
              },
              "allAvailableIfToBeTrue": {
                "type": "boolean"
              },
              "notAvailableIf": {
                "type": "array",
                "items": {
                  "$ref": "#/definitions/SingleCondition"
                }
              },
              "allNotAvailableIfToBeTrue": {
                "type": "boolean"
              }
            }
          },
          "SingleCondition": {
            "type": "object",
            "properties": {
              "step": {
                "type": "string"
              },
              "quest": {
                "type": "string"
              },
              "characteristic": {
                "type": "string"
              },
              "item": {
                "type": "string"
              },
              "resultReferenceKey": {
                "type": "string"
              },
              "value": {
                "type": "string"
              }
            }
          },
          "ValueWithPrecondition": {
            "type": "object",
            "properties": {
              "value": {
                "type": "string"
              },
              "precondition": {
                "$ref": "#/definitions/Precondition"
              }
            }
          },
          "OnStepResult": {
            "type": "object",
            "properties": {
              "result": {
                "type": "string",
                "enum": ["success", "fail", "all"]
              },
              "looseItem": {
                "type": "string"
              }
            }
          }
        }
      }
    }]
  });
}
