import {Component, OnDestroy, OnInit, signal} from '@angular/core';
import {ExplorationService, Obelisk} from 'quest-atlas-angular-components';
import {Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalButton} from '../../common-components/modal/modal.component';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-exploration',
  templateUrl: './exploration.component.html',
  styleUrls: ['./exploration.component.scss']
})
export class ExplorationComponent implements OnInit, OnDestroy {

  obelisks: Obelisk[] = [];
  showDeleteConfirmation = signal(false);
  obeliskToDelete = signal<Obelisk>(null);

  modalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.showDeleteConfirmation.set(false);
        this.obeliskToDelete.set(null);
      }
    },
    {
      type: 'error',
      text: this.transloco.translate('delete'),
      onClick: () => this.deleteObelisk(this.obeliskToDelete())
    }
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private explorationService: ExplorationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transloco: TranslocoService
  ) {
  }

  ngOnInit() {
    this.loadObelisks();
  }

  loadObelisks() {
    this.explorationService.loadObelisks$([]).pipe(takeUntil(this.destroy$)).subscribe((obelisks) => {
      this.obelisks = obelisks;
    });
  }

  goToEditObelisk(obelisk: Obelisk) {
    this.router.navigate(['obelisks-editor', obelisk.uuid]);
  }

  handleDeleteObelisk(obelisk: Obelisk) {
    this.obeliskToDelete.set(obelisk);
    this.showDeleteConfirmation.set(true);
  }

  deleteObelisk(obelisk: Obelisk) {
    this.explorationService.deleteObelisk$(obelisk).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadObelisks();
      this.showDeleteConfirmation.set(false);
      this.obeliskToDelete.set(null);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
