import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './auth/auth.service';
import {FacebookLoginProvider} from '@abacritt/angularx-social-login';
import {environment} from '../environments/environment';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {HomeComponent} from './pages/home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DropdownComponent} from './common-components/dropdown/dropdown.component';
import {StepperComponent} from './common-components/stepper/stepper.component';
import {QuestEditorComponent} from './pages/quest-editor/quest-editor.component';
import {GeneralFormComponent} from './pages/quest-editor/general-form/general-form.component';
import {MatInputModule} from '@angular/material/input';
import {OptionsFormComponent} from './pages/quest-editor/options-form/options-form.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {ModalComponent} from './common-components/modal/modal.component';
import {StepsFormComponent} from './pages/quest-editor/steps-form/steps-form.component';
import {TimeEditComponent} from './common-components/time-edit/time-edit.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MapEditorComponent} from './pages/quest-editor/map-editor/map-editor.component';
import {PassiveCheckComponent} from './common-components/passive-check/passive-check.component';
import {ElementRowComponent} from './pages/quest-editor/map-editor/element-row/element-row.component';
import {PublishViewComponent} from './pages/quest-editor/publish-view/publish-view.component';
import {TooltipComponent} from './common-components/tooltip/tooltip.component';
import {NgxPopperjsModule} from 'ngx-popperjs';
import {AuthInterceptorService} from './interceptors/auth-interceptor.service';
import {QuestListComponent} from './pages/quest-list/quest-list.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {SidebarNavComponent} from './pages/sidebar-nav/sidebar-nav.component';
import {MatSortModule} from '@angular/material/sort';
import {ActiveChipComponent} from './common-components/active-chip/active-chip.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {TagComponent} from './common-components/tag/tag.component';
import {QuestViewComponent} from './common-components/quest-view/quest-view.component';
import {QuestSinglePageComponent} from './pages/quest-single-page/quest-single-page.component';
import {
  QuestPublishRequestsAdminComponent
} from './pages/quest-publish-requests-admin/quest-publish-requests-admin.component';
import {QuestAdminSinglePageComponent} from './pages/quest-admin-single-page/quest-admin-single-page.component';
import {ForbiddenTooManyQuestsComponent} from './pages/forbidden-too-many-quests/forbidden-too-many-quests.component';
import {QuestsTableListComponent} from './common-components/quests-table-list/quests-table-list.component';
import {QuestsChainsListComponent} from './pages/quests-chains-list/quests-chains-list.component';
import {ExplorationComponent} from './pages/exploration/exploration.component';
import {ObelisksEditorComponent} from './pages/obelisks-editor/obelisks-editor.component';
import {ChooserComponent} from './common-components/chooser/chooser.component';
import {PrivacyPolicyComponent} from './common-components/privacy-policy/privacy-policy.component';
import {TermsOfUseComponent} from './common-components/terms-of-use/terms-of-use.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ErrorsInterceptorService} from './interceptors/errors-interceptor.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslocoRootModule} from './transloco-root.module';
import {ProfileComponent} from './common-components/profile/profile.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {QuestAcceptShareComponent} from './pages/quest-accept-share/quest-accept-share.component';
import {OdmListComponent} from './pages/odm-list/odm-list.component';
import {OdmComponent} from './pages/odm-list/odm/odm.component';
import * as Sentry from "@sentry/angular-ivy";
import {Router} from '@angular/router';
import {EnvProviderService} from 'quest-atlas-angular-components';
import {
  DisplayMapComponent
} from 'quest-atlas-angular-components';
import {EmbeddedMapComponent} from './pages/embedded-map/embedded-map.component';
import {ProxyToMapRedirectComponent} from './pages/proxy-to-map-redirect/proxy-to-map-redirect.component';
import {
  AdaptiveStepEditingFormComponent
} from './pages/quest-editor/adaptive-step-editing-form/adaptive-step-editing-form.component';
import {MonacoEditorModule, NgxMonacoEditorConfig} from 'ngx-monaco-editor-v2';
import {onMonacoLoad} from './monaco.stuff';
import {ObelisksTableListComponent} from './common-components/obelisks-table-list/obelisks-table-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DropdownComponent,
    StepperComponent,
    QuestEditorComponent,
    GeneralFormComponent,
    OptionsFormComponent,
    StepsFormComponent,
    TimeEditComponent,
    MapEditorComponent,
    PassiveCheckComponent,
    ElementRowComponent,
    PublishViewComponent,
    TooltipComponent,
    QuestListComponent,
    SidebarNavComponent,
    ActiveChipComponent,
    NotFoundComponent,
    TagComponent,
    QuestViewComponent,
    QuestSinglePageComponent,
    QuestPublishRequestsAdminComponent,
    QuestAdminSinglePageComponent,
    ForbiddenTooManyQuestsComponent,
    QuestsChainsListComponent,
    ExplorationComponent,
    ObelisksEditorComponent,
    ChooserComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    ProfileComponent,
    QuestAcceptShareComponent,
    OdmListComponent,
    OdmComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatButtonModule,
        HttpClientModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FormsModule,
        NgxPopperjsModule.forRoot({}),
        MatSlideToggleModule,
        MatIconModule,
        MatTableModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSortModule,
        QuestsTableListComponent,
        ModalComponent,
        MatCheckboxModule,
        MatSnackBarModule,
        TranslocoRootModule,
        NgxMaskDirective,
        NgxMaskPipe,
        DisplayMapComponent,
        EmbeddedMapComponent,
        ProxyToMapRedirectComponent,
        AdaptiveStepEditingFormComponent,
        MonacoEditorModule.forRoot({
            baseUrl: 'assets',
            defaultOptions: {scrollBeyondLastLine: false},
            onMonacoLoad
        }),
        ObelisksTableListComponent
    ],
  providers: [
    AuthService,
    {
      provide: EnvProviderService,
      useValue: {environment: environment}
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID)
          // },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FACEBOOK_CLIENT_ID)
          }
        ]
      }
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptorService, multi: true},
    provideNgxMask(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  exports: [
    QuestViewComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
