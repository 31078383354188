import {Dict} from 'quest-atlas-angular-components';
import {Observable} from 'rxjs';

export function updateFormValuesObjectFromLocalStorage(formObject: any, storageKey: string): Dict<any> {
  const newFormObject = {...formObject};
  const fromLS = localStorage.getItem(storageKey);

  if (!fromLS) {
    return newFormObject;
  }

  const fromLSObject = JSON.parse(fromLS);

  for (const [key, value] of Object.entries(fromLSObject)) {
    if (!newFormObject.hasOwnProperty(key)) {
      newFormObject[key] = value;
    }
  }

  return newFormObject;
}

export function updateFormValuesObjectFromIDB(formObject: any, storage: string, db: IDBDatabase): Observable<Dict<any>> {
  const newFormObject = {...formObject};

  return new Observable<Dict<any>>(subscriber => {
    const request = db.transaction(storage, 'readonly').objectStore(storage).getAll();
    request.onsuccess = (event) => {
      let result = (event.target as any).result;

      if (!result?.length) {
        result = [{}];
      }

      subscriber.next({...newFormObject, ...result[0]});
      subscriber.complete();
    }
    request.onerror = (event) => {
      subscriber.error(event);
    }
  });
}
